
import { Vue, Component, Prop } from "vue-property-decorator";
import CreditPolicy from "@/types/credit-policy";

@Component
export default class CreditPolicyDeleteModal extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop({ default: null }) readonly index!: number;
  @Prop({ default: {} }) readonly item!: CreditPolicy;

  constructor() {
    super();
  }
}
