
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import LoanService from "@/services/loan-service";
import Modal from "@/components/Modal.vue";

import DatePicker from "@/components/DatePicker.vue";

import LoanStatusGroup from "@/types/loan-status-group";
import { format as formatCNPJ } from "@/utils/cnpj";
import {
  LoanRemittanceDetailsReportsFilters,
  LoanRemittanceDetailsReportsItemsList
} from "@/services/loan-service/types/management-reports";
import CompanyService, {
  ListCompaniesResponse
} from "@/services/company-service";
import { format } from "@/utils/cpf";
import Company from "@/types/company";
import dayjs from "dayjs";
import formatCurrency from "@/utils/formatCurrency";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";

@Component({
  components: {
    Modal,
    DatePicker
  }
})
export default class RemittancesFundsDetailsReport extends Vue {
  @Prop() readonly remittanceId?: number;
  @Prop() readonly contract?: string;
  loanService: LoanService;
  remittances: LoanRemittanceDetailsReportsItemsList;
  filters: LoanRemittanceDetailsReportsFilters;
  selectedCompany: Partial<Company> | null = null;
  companyService: CompanyService;
  headers: Array<DataTableHeader>;
  companiesList: ListCompaniesResponse = {
    items: [],
    total: 0
  };
  formatCNPJ = formatCNPJ;
  formatCPF = format;
  formatCurrency = formatCurrency;
  dateFilterTypes: Array<string> = ["Data de Remessa", "Data de Vencimento"];
  dateFilterType = "Data de Remessa";
  fundTypes: Array<{
    name: string;
    id: number;
  }>;
  typeOfOcorrences: Array<{ id: number; name: string }>;
  loading = true;
  loadingExcel = false;
  statusGroupList: LoanStatusGroup[] | null = null;
  fileManagerUrl: string;

  constructor() {
    super();
    this.loanService = LoanService.getInstance();
    this.companyService = CompanyService.getInstance();
    this.headers = [
      { text: "Remessa", value: "remittance.id", sortable: true },
      { text: "Tipo", value: "typeOfOcorrence.name", sortable: false },
      {
        text: "Data da Remessa",
        value: "remittance.createdAt",
        sortable: true
      },
      {
        text: "Valor da Aquisição",
        value: "loanInstallment.acquisitionValue",
        sortable: false
      },
      {
        text: "Data de Vencimento",
        value: "loanInstallment.dueDate",
        sortable: false
      },
      {
        text: "Valor Crédito",
        value: "loan.creditAmount",
        sortable: false
      },
      { text: "Contrato", value: "loan.proposalNumber", sortable: false },
      { text: "Proposta", value: "loan.id", sortable: false },
      { text: "Nome/CPF tomador", value: "loan.borrowerName", sortable: false },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        cellClass: "text-end",
        class: "text-end"
      }
    ];
    this.fundTypes = [
      {
        name: "Money Plus",
        id: 1
      },
      {
        name: "Limine",
        id: 2
      },
      {
        name: "Santa Bárbara",
        id: 3
      },
      {
        name: "Gooroo",
        id: 4
      }
    ];
    this.typeOfOcorrences = [
      { name: "Aquisição", id: 1 },
      { name: "Conciliação", id: 2 },
      { name: "Aquisição Gooroo", id: 4 },
      { name: "Conciliação Gooroo", id: 5 }
    ];
    this.filters = {
      page: 1,
      limit: 10,
      sort: "id:ASC",
      remittance: this.remittanceId ? String(this.remittanceId) : null,
      typeOfOcorrences: null,
      companyId: null,
      cnpj: null,
      companyName: null,
      dateRemittanceDueDateStart: null,
      dateRemittanceDueDateEnd: null,
      dateRemittanceStart:
        this.remittanceId || this.contract
          ? null
          : String(dayjs().subtract(7, "days").format("YYYY-MM-DD")),
      dateRemittanceEnd: null,
      propose: "",
      borrowerCpf: "",
      fundName: null,
      contract: this.contract ?? null
    };
    this.remittances = {
      items: [],
      total: 0
    };
    this.fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL || "";
  }

  created(): void {
    this.loadFilters();
  }

  async filterFromStart(): Promise<void> {
    this.filters.page = 1;
    this.loadRemittancesDetails();
  }

  async loadRemittancesDetails(): Promise<void> {
    this.loading = true;

    const [remittancesLoansReportError, remittancesLoansReportData] =
      await this.loanService.getLoanRemittanceDetailsReports(this.filters);

    if (remittancesLoansReportError) {
      this.$notify({
        type: "error",
        text: "Ocorreu um erro ao buscar os dados do relatório"
      });
    } else if (remittancesLoansReportData) {
      this.remittances = remittancesLoansReportData;
    }
    this.loading = false;
  }

  async downloadExcel(): Promise<void> {
    this.loadingExcel = true;

    const [error, data] =
      await this.loanService.generateRemittanceDetailsFundsReportXls(
        this.filters
      );
    if (!error) {
      window.open(
        process.env.VUE_APP_LOAN_URL! +
          "/reports/download-xls?token=" +
          data!.token,
        "_blank"
      );
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loadingExcel = false;
  }

  onOptionsChange(tableOptions: DataOptions): DataOptions {
    this.filters.page = tableOptions.page;
    this.filters.limit = tableOptions.itemsPerPage;
    this.filters.sort = this.formatSort(
      tableOptions.sortBy,
      tableOptions.sortDesc
    );
    this.loadRemittancesDetails();

    return tableOptions;
  }

  formatSort(sortBy: Array<string>, sortDesc: Array<boolean>): string {
    const attr = sortBy[0] ? sortBy[0].split(".")[1] : "id";
    const order = sortDesc[0] ? "DESC" : "ASC";

    return `${attr}:${order}`;
  }

  async loadFilters(): Promise<void> {
    const [companiesError, companiesData] =
      await this.companyService.listCompanies({
        page: 1,
        limit: 100000,
        sort: "name:ASC",
        search: ""
      });
    if (companiesError) {
      this.$notify({
        type: "error",
        text: "Ocorreu um erro ao buscar os dados do relatório"
      });
    } else if (companiesData) {
      this.companiesList = companiesData;
    }
  }

  updateCompanyFilters(): void {
    if (this.selectedCompany) {
      this.filters.companyId = this.selectedCompany.id;
      this.filters.cnpj = this.selectedCompany.cnpj;
      this.filters.companyName = this.selectedCompany.name;
    } else {
      this.filters.companyId = null;
      this.filters.cnpj = null;
      this.filters.companyName = null;
    }
  }

  @Watch("dateFilterType")
  onDateFilterTypeChange(): void {
    this.filters.dateRemittanceStart = null;
    this.filters.dateRemittanceEnd = null;
    this.filters.dateRemittanceDueDateStart = null;
    this.filters.dateRemittanceDueDateEnd = null;
  }

  formatDate(date: string): string {
    if (!date) return "";
    const [data] = date.split("T");
    const [ano, mes, dia] = data.split("-");
    const dataFormatada = `${dia}-${mes}-${ano}`;
    return dataFormatada;
  }
}
