
import { Vue, Component } from "vue-property-decorator";
import Page from "@/components/core/dashboard/Page.vue";
import GroupsLoansReport from "@/components/management-reports/GroupsLoansReport.vue";
import CompaniesLoansReport from "@/components/management-reports/CompaniesLoansReport.vue";
import BorrowersLoansReport from "@/components/management-reports/BorrowersLoansReport.vue";
import LoanInstallmentsReport from "@/components/management-reports/LoanInstallmentsReport.vue";
import PartnersCommissionsReport from "@/components/management-reports/PartnersCommissionsReport.vue";
import RemittanceFundsReport from "@/components/management-reports/RemittanceFundsReport.vue";
import RemittancesFundsDetailsReport from "@/components/management-reports/RemittancesFundsDetailsReport.vue";
import FilesRemittanceFundsReport from "@/components/management-reports/FilesRemittanceFundsReport.vue";
import ReconciliateDetailsReport from "@/components/management-reports/ReconciliateDetailsReport.vue";

@Component({
  components: {
    Page,
    GroupsLoansReport,
    CompaniesLoansReport,
    BorrowersLoansReport,
    LoanInstallmentsReport,
    PartnersCommissionsReport,
    FilesRemittanceFundsReport,
    RemittanceFundsReport,
    RemittancesFundsDetailsReport,
    ReconciliateDetailsReport
  }
})
export default class ManagementReports extends Vue {
  active: string | null = null;
  constructor() {
    super();
  }

  get isAdminGooroo() {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "ADMIN_GOOROO"
    );
  }
}
