
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import CompanyService from "@/services/company-service";
import BorrowerService from "@/services/borrower-service";
import LoanService from "@/services/loan-service";
import Modal from "@/components/Modal.vue";

import DatePicker from "@/components/DatePicker.vue";
import Group from "@/types/group";
import Company from "@/types/company";
import { format as formatCNPJ } from "@/utils/cnpj";
import { format as formatCPF } from "@/utils/cpf";
import LoanInstallmentsReport from "@/components/management-reports/LoanInstallmentsReport.vue";
import {
  BorrowersLoansReportData,
  BorrowersLoansReportFilters
} from "@/services/loan-service/types/management-reports";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import LoanStatusGroup from "@/types/loan-status-group";
import LoanStatus from "@/types/loan-status";
import Partner from "@/types/partner";
import PartnerService from "@/services/partner-service";
import SafetyService from "@/services/safety-service";
import User from "../../types/user";

@Component({
  components: {
    Modal,
    DatePicker,
    LoanInstallmentsReport
  }
})
export default class BorrowersLoansReport extends Vue {
  @Prop() readonly companyId?: number;
  formatCNPJ = formatCNPJ;
  formatCPF = formatCPF;
  borrowerCpf: string | null = null;
  groupsList: Array<Group>;
  companiesList: Array<Company>;
  partnerService: PartnerService;
  borrowerService: BorrowerService;
  companyService: CompanyService;
  loanService: LoanService;
  safetyService: SafetyService;
  reports: BorrowersLoansReportData;
  filters: BorrowersLoansReportFilters;
  headers: Array<DataTableHeader>;
  loading = true;
  loadingXls = false;
  subPartnerList: Array<Partner>;
  dateFilterTypes: Array<string> = [
    "Data de contratação",
    "Data de início da simulação",
    "Data de vencimento da parcela",
    "Data de pagamento"
  ];
  dateFilterType = "Data de contratação";
  statusList: LoanStatus[] | null = null;
  statusGroupList: LoanStatusGroup[] | null = null;
  usersList: User[];

  constructor() {
    super();
    this.companyService = CompanyService.getInstance();
    this.partnerService = PartnerService.getInstance();
    this.borrowerService = BorrowerService.getInstance();
    this.loanService = LoanService.getInstance();
    this.safetyService = SafetyService.getInstance();
    this.groupsList = [];
    this.companiesList = [];
    this.subPartnerList = [];
    this.usersList = [];

    this.headers = [
      { text: "Empresa", value: "companyName", sortable: false },
      { text: "CNPJ", value: "companyCnpj", sortable: false },
      { text: "Nome Colaborador", value: "borrowerName", sortable: false },
      { text: "CPF Colaborador", value: "borrowerCpf", sortable: false },
      { text: "Valor líquido", value: "loanRequestedAmount", sortable: false },
      {
        text: "Valor do crédito",
        value: "loanCreditAmount",
        sortable: false
      },
      { text: "Prazo", value: "loanNumInstallments", sortable: false },
      { text: "Status", value: "status.description", sortable: false },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        cellClass: "text-end",
        class: "text-end"
      }
    ];
    this.filters = {
      page: 1,
      limit: 10,
      sort: "borrowerName:ASC",
      borrowerSearch: "",
      subpartnerId: null,
      groupId: null,
      companyId: this.companyId || null,
      registrationResponsibleUserId: null,
      formalizationResponsibleUserId: null,
      loanInstallmentDueDateStart: null,
      loanInstallmentDueDateEnd: null,
      startedSimulationDateStart: null,
      startedSimulationDateEnd: null,
      paidDateStart: null,
      paidDateEnd: null,
      requestDateStart: null,
      requestDateEnd: null,
      statusGroupId: 2
    };
    this.reports = {
      items: [],
      total: 0
    };
  }

  get companiesListFilteredByGroup(): Array<Company> {
    if (this.filters.groupId) {
      return this.companiesList.filter(
        (company) => company.groupId === this.filters.groupId
      );
    }
    return this.companiesList;
  }

  created(): void {
    this.loadFilters();
    this.loadPartnersList();
  }

  loadFromStart() {
    this.filters.page = 1;
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.loading = true;

    const [borrowersLoansReportError, borrowersLoansReportData] =
      await this.loanService.getBorrowersLoansReport(this.filters);

    if (borrowersLoansReportError) {
      this.$notify({
        type: "error",
        text: "Ocorreu um erro ao buscar os dados do relatório"
      });
    } else if (borrowersLoansReportData) {
      this.reports = borrowersLoansReportData;
    }

    this.loading = false;
  }
  async loadPartnersList(): Promise<void> {
    const [partnersError, partnersData] =
      await this.partnerService.listPartners({
        page: 1,
        limit: -1
      });

    if (!partnersData || partnersError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de parceiros para o filtro."
      });
    } else {
      this.subPartnerList = partnersData.data;
    }
  }

  async loadFilters(): Promise<void> {
    const [groupsError, groupsData] = await this.companyService.listGroups({
      page: 1,
      limit: -1,
      sort: "name:ASC",
      groupName: "",
      companyNameOrCnpj: "",
      adminEmail: "",
      adminPhone: ""
    });
    if (groupsData) {
      this.groupsList = groupsData.items;
    }
    const [companiesError, companiesData] =
      await this.companyService.listCompanies({
        page: 1,
        limit: -1,
        sort: "name:ASC",
        search: ""
      });
    if (companiesData) {
      this.companiesList = companiesData.items;
    }
    if (groupsError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de Grupos para o filtro."
      });
    }
    if (companiesError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de Empresas para o filtro."
      });
    }
    this.loadStatusList();
    this.loadStatusGroupList();
    this.loadUsers();
  }

  async loadUsers(): Promise<void> {
    const [usersListError, usersList] = await this.safetyService.listUsers({
      page: 1,
      limit: -1,
      types: ["ADMIN_GOOROO", "PARTNER_MASTER", "PARTNER"]
    });

    if (usersListError || !usersList) {
      return this.$notify({
        type: "error",
        text: "Não foi possível carregar os usuários."
      });
    }

    this.usersList = usersList.items;
  }

  async loadStatusList(): Promise<void> {
    const [statusListError, statusList] =
      await this.loanService.getStatusList();
    this.statusList = statusList;

    if (statusListError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de status para o filtro."
      });
    }
  }
  async loadStatusGroupList(): Promise<void> {
    const [statusGroupListError, statusGroupList] =
      await this.loanService.getStatusGroupList();
    this.statusGroupList = statusGroupList;

    if (statusGroupListError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de grupos de status para o filtro."
      });
    }
  }
  async downloadXls(): Promise<void> {
    this.loadingXls = true;
    const [error, data] =
      await this.loanService.generateBorrowersLoansReportXls(this.filters);
    if (!error) {
      window.open(
        process.env.VUE_APP_LOAN_URL! +
          "/reports/download-xls?token=" +
          data!.token,
        "_blank"
      );
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loadingXls = false;
  }

  @Watch("subpartnerId")
  updateSubPartnerFilter(subpartner: number): void {
    this.filters.subpartnerId = subpartner;
  }

  onOptionsChange(tableOptions: DataOptions): DataOptions {
    this.filters.page = tableOptions.page;
    this.filters.limit = tableOptions.itemsPerPage;
    this.filters.sort = this.formatSort(
      tableOptions.sortBy,
      tableOptions.sortDesc
    );
    this.loadData();

    return tableOptions;
  }

  @Watch("dateFilterType")
  onDateFilterTypeChange(): void {
    this.filters.loanInstallmentDueDateStart = null;
    this.filters.loanInstallmentDueDateEnd = null;
    this.filters.startedSimulationDateStart = null;
    this.filters.startedSimulationDateEnd = null;
    this.filters.paidDateStart = null;
    this.filters.paidDateEnd = null;
    this.filters.requestDateStart = null;
    this.filters.requestDateEnd = null;
  }

  formatSort(sortBy: Array<string>, sortDesc: Array<boolean>): string {
    const attr = sortBy[0] ?? "companyName";
    const order = sortDesc[0] ? "ASC" : "DESC";

    return `${attr}:${order}`;
  }

  getUserType(type: string): string | void {
    const userType = this.safetyService.userTypes.find((t) => t.value == type);
    if (userType) {
      return userType.text;
    }
  }

}
