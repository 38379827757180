
import { Vue, Component, Watch } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import LoanService from "@/services/loan-service";
import Modal from "@/components/Modal.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import DatePicker from "@/components/DatePicker.vue";
import {
  LoanRemittanceDetailsReportsItemsList,
  FilesRemittancesFundsReportFilters,
  FilesRemittancesFundsReportList
} from "@/services/loan-service/types/management-reports";
import LoanStatusGroup from "@/types/loan-status-group";
import formatDatetime from "@/utils/formatDatetime";
import { format as formatCNPJ } from "@/utils/cnpj";
import { format } from "@/utils/cpf";
import RemittanceFundsReport from "./RemittanceFundsReport.vue";
import RemittancesFundsDetailsReport from "./RemittancesFundsDetailsReport.vue";
import formatCurrency from "@/utils/formatCurrency";
import { RemittanceOccurrenceTypeEnum } from "@/types/remittance-occurrence-type";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import GenerateRemittanceCnabFromXlsParams from "@/services/loan-service/types/generate-remittance-cnab-from-xls-params";

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    Modal,
    DatePicker,
    RemittanceFundsReport,
    RemittancesFundsDetailsReport
  }
})
export default class FilesRemittanceFundsReport extends Vue {
  loanService: LoanService;
  remittancesDetails: LoanRemittanceDetailsReportsItemsList;
  selectedItem: FilesRemittanceFundsReport | null = null;
  remittances: FilesRemittancesFundsReportList;
  filters: FilesRemittancesFundsReportFilters;
  headers: Array<DataTableHeader>;
  formatCNPJ = formatCNPJ;
  formatCPF = format;
  formatCurrency = formatCurrency;
  formatDatetime = formatDatetime;
  RemittanceOccurrenceTypeEnum = RemittanceOccurrenceTypeEnum;
  fundTypes: Array<{ name: string; id: number }>;
  loading = true;
  loadingExcel = false;
  statusGroupList: LoanStatusGroup[] | null = null;
  fileManagerUrl: string;
  showImportFileModal: boolean = false;
  selectedImportType: any = null;
  importFileForm: GenerateRemittanceCnabFromXlsParams = {
    file: null,
    financialId: null
  };
  loadingImportFile: boolean = false;
  supportedFinancialCompaniesForImport = [
    { id: 2, name: "Limine" },
    { id: 3, name: "Santa Bárbara" }
  ];

  importFileTypes = [
    {
      id: "updateInstallments",
      title: "Atualizar Parcelas",
      downloadUrl: "/files/Modelo-arquivo-conciliacao-Gooroo-Credito.xlsx",
      buttonLabel: "Atualizar Parcelas",
      additionalInfo: "Tipo de ocorrência deve ser preenchido com 06.",
      validationMessage:
        "Verifique o horário de disponibilidade da financeira para recebimento do arquivo de conciliação, ou o processo irá falhar",
      action: () => this.updateInstallmentsDueDateFromXls(this.importFileForm)
    },
    {
      id: "conciliation",
      title: "Importar Arquivo Conciliação",
      downloadUrl: "/files/Modelo-arquivo-conciliacao-Gooroo-Credito.xlsx",
      buttonLabel: "Importar Arquivo Conciliação",
      validationMessage:
        "Verifique o horário de disponibilidade da financeira para recebimento do arquivo de conciliação, ou o processo irá falhar",
      action: () => this.generateRemittanceCnabFromXls(this.importFileForm)
    }
  ];

  constructor() {
    super();
    this.loanService = LoanService.getInstance();
    this.headers = [
      {
        text: "Remessa",
        value: "remittance.id",
        sortable: true
      },
      { text: "Data", value: "generationDate", sortable: false },
      { text: "Quantidade de registros", value: "registers", sortable: false },
      { text: "QTd de CCbs", value: "ccbGenerated", sortable: false },
      {
        text: "Valor Total Aquisição",
        value: "installmentsTotal",
        sortable: false
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        cellClass: "text-end",
        class: "text-end"
      }
    ];
    this.fundTypes = [
      {
        name: "Money Plus",
        id: 1
      },
      {
        name: "Limine",
        id: 2
      },
      {
        name: "Santa Bárbara",
        id: 3
      },
      {
        name: "Gooroo",
        id: 4
      }
    ];
    this.remittancesDetails = {
      items: [],
      total: 0
    };
    this.filters = {
      page: 1,
      limit: 10,
      sort: "id:ASC",
      fundName: null,
      dateStart: null,
      dateEnd: null
    };
    this.remittances = {
      items: [],
      total: 0
    };
    this.fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL || "";
  }

  async loadRemittances(): Promise<void> {
    this.loading = true;

    const validation =
      this.filters.dateStart &&
      this.filters.dateEnd &&
      this.filters.dateStart > this.filters.dateEnd
        ? "a data final não pode ser menor que a data inicial"
        : undefined;
    const [remittanceReportError, remittanceLoansReportData] =
      await this.loanService.getFilesRemittancesReports(this.filters);

    if (remittanceReportError) {
      this.$notify({
        type: "error",
        text: validation
          ? validation
          : "Ocorreu um erro ao buscar os dados do relatório"
      });
    } else if (remittanceLoansReportData) {
      this.remittances = remittanceLoansReportData;
    }

    this.loading = false;
  }

  async filterFromStart(): Promise<void> {
    this.filters.page = 1;
    this.loadRemittances();
  }

  openImportModal(importType) {
    this.selectedImportType = importType;
    this.showImportFileModal = true;
  }

  async importFile(selectedImportType) {
    this.loadingImportFile = true;
    const [error] = await selectedImportType.action();

    if (error) {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
      this.resetFileInput();
    } else {
      this.$notify({ type: "success", text: "Arquivo importado com sucesso" });
      this.closeImportFileModal();
    }
    this.loading = false;
    this.loadingImportFile = false;

    return;
  }

  resetFileInput() {
    this.importFileForm.file = null;
    this.importFileForm.financialId = null;
  }

  closeImportFileModal() {
    this.resetFileInput();
    this.showImportFileModal = false;
  }

  async downloadXls(): Promise<void> {
    this.loadingExcel = true;
    const [error, data] = await this.loanService.generateFileFundsReportXls(
      this.filters
    );
    if (!error) {
      window.open(
        process.env.VUE_APP_LOAN_URL! +
          "/reports/download-xls?token=" +
          data!.token,
        "_blank"
      );
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loadingExcel = false;
  }

  onOptionsChange(tableOptions: DataOptions): DataOptions {
    this.filters.page = tableOptions.page;
    this.filters.limit = tableOptions.itemsPerPage;
    this.filters.sort = this.formatSort(
      tableOptions.sortBy,
      tableOptions.sortDesc
    );
    this.loadRemittances();

    return tableOptions;
  }

  formatSort(sortBy: Array<string>, sortDesc: Array<boolean>): string {
    const attr = sortBy[0] ? sortBy[0].split(".")[1] : "id";
    const order = sortDesc[0] ? "DESC" : "ASC";

    return `${attr}:${order}`;
  }

  @Watch("dateFilterType")
  onDateFilterTypeChange(): void {
    this.filters.dateStart = null;
    this.filters.dateEnd = null;
  }

  formatDate(date: string): string {
    if (!date) return "";
    const [data] = date.split("T");
    const [ano, mes, dia] = data.split("-");
    const dataFormatada = `${dia}-${mes}-${ano}`;
    return dataFormatada;
  }

  async updateInstallmentsDueDateFromXls(
    formData: GenerateRemittanceCnabFromXlsParams
  ) {
    return await this.loanService.updateInstallmentsDueDateFromXls(formData);
  }

  async generateRemittanceCnabFromXls(
    formData: GenerateRemittanceCnabFromXlsParams
  ) {
    return await this.loanService.generateRemittanceCnabFromXls(formData);
  }
}
