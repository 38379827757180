
import dayjs from "@/plugins/day-js";
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import CompanyService from "@/services/company-service";
import BorrowerService from "@/services/borrower-service";
import LoanService from "@/services/loan-service";
import Modal from "@/components/Modal.vue";
import DatePicker from "@/components/DatePicker.vue";
import Group from "@/types/group";
import Company from "@/types/company";
import { format as formatCNPJ } from "@/utils/cnpj";
import { format as formatCPF } from "@/utils/cpf";
import {
  LoanInstallmentsReportData,
  LoanInstallmentsReportFilters
} from "@/services/loan-service/types/management-reports";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import LoanStatusGroup from "@/types/loan-status-group";
import { MonthData } from "../../types/dashboard-reports";
import { MonthShortAndLongForm } from "@/utils/months";
import formatCurrency from "@/utils/formatCurrency";
import calculateDiffInPercentage from "@/utils/calculate-diff-in-percentage";
import DashboardReportPercentage from "@/components/DashboardReportPercentage.vue";
import LoanInstallmentsStatusReportResponse, {
  LoanInstallmentsStatusReportInMonth,
  LoanInstallmentsStatusReportData
} from "@/services/loan-service/types/loan-installments-status-report-response";
import { DoughnutChart } from "vue-chart-3";
import { Chart, registerables, ChartOptions } from "chart.js";
import LoanStatus from "@/types/loan-status";
import LoanInstallmentStatus from "@/types/loan-installment-status";

Chart.register(...registerables);

type ExtendedLoanInstallmentsStatusReportResponse = Array<
  Omit<LoanInstallmentsStatusReportInMonth, "value"> & {
    value: Array<
      LoanInstallmentsStatusReportData & { backgroundColor: string }
    >;
  }
>;

@Component({
  components: {
    Modal,
    DatePicker,
    LoanInstallmentsReport,
    DashboardReportPercentage,
    DoughnutChart
  }
})
export default class LoanInstallmentsReport extends Vue {
  @Prop() readonly borrowerCpf?: string;
  formatCNPJ = formatCNPJ;
  formatCPF = formatCPF;
  formatCurrency = (value: number) => {
    if (value >= 10000) return formatCurrency(value, "compact");
    return formatCurrency(value);
  };
  calculateDiffInPercentage = calculateDiffInPercentage;
  MonthShortAndLongForm = MonthShortAndLongForm;
  groupsList: Array<Group>;
  companiesList: Array<Company>;
  borrowerService: BorrowerService;
  companyService: CompanyService;
  loanService: LoanService;
  reports: LoanInstallmentsReportData;
  filters: LoanInstallmentsReportFilters;
  headers: Array<DataTableHeader>;
  loading = true;
  loadingXls = false;
  dateFilterTypes: Array<string> = [
    "Data de contratação",
    "Data de início da simulação",
    "Data de vencimento da parcela"
  ];
  dateFilterType = "Data de contratação";
  statusGroupList: LoanStatusGroup[] | null = null;

  dashboardLastGroupFilter: number | null = null;

  /*
   * "Regular" reports
   */
  creditValueSumByMonthReport: MonthData[] | null = null;
  installmentValueSumByMonthReport: MonthData[] | null = null;
  installmentDebitBalanceValueSumByMonthReport: MonthData[] | null = null;
  dataByMonthTab: number = 0;
  expandedRegularReport: MonthData[] | null = null;
  expandedRegularReportTitle: string | null = null;
  expandedRegularReportSubtitle: string | null = null;
  formatExpandedRegularReportAsCurrency: boolean | null = null;

  /*
   * "Installment status" report
   */
  installmentStatusByMonthReport: ExtendedLoanInstallmentsStatusReportResponse | null =
    null;
  installmentStatusByMonthChartData: {
    labels: string[];
    datasets: Array<{ backgroundColor: string[]; data: number[] }>;
  } | null = null;
  installmentStatusByMonthChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right",
        align: "center",
        labels: {
          usePointStyle: true,
          pointStyle: "circle"
        }
      }
    }
  };
  installmentStatusBackgroundColors = [
    "#3078af",
    "#abcde1",
    "#6bad88",
    "#a379b5",
    "#e0b3f2",
    "#b5ae79"
  ];
  expandedInstallmentStatusReport: ExtendedLoanInstallmentsStatusReportResponse | null =
    null;
  expandedInstallmentStatusReportTitle: string | null = null;
  loanStatusList: LoanStatus[] | null = null;
  loanInstallmentStatusList: LoanInstallmentStatus[] | null = null;

  constructor() {
    super();
    this.companyService = CompanyService.getInstance();
    this.borrowerService = BorrowerService.getInstance();
    this.loanService = LoanService.getInstance();
    this.groupsList = [];
    this.companiesList = [];

    this.headers = [
      { text: "Empresa Vinculada", value: "companyName", sortable: false },
      { text: "CNPJ", value: "companyCnpj", sortable: false },
      { text: "Nome Colaborador", value: "borrowerName", sortable: false },
      { text: "CPF Colaborador", value: "borrowerCpf", sortable: false },
      { text: "Valor líquido", value: "loanRequestedAmount", sortable: false },
      { text: "Valor do crédito", value: "loanCreditAmount", sortable: false },
      { text: "Valor Parcela", value: "installmentValue", sortable: true },
      { text: "Data Vencimento", value: "installmentDueDate", sortable: true },
      { text: "Parcela", value: "installmentNumber", sortable: true },
      { text: "Prazo", value: "loanNumInstallments", sortable: false },
      {
        text: "Saldo devedor",
        value: "installmentDebitBalanceValue",
        sortable: true
      },
      {
        text: "Status",
        value: "installmentStatus.readableName",
        sortable: false
      }
    ];
    this.filters = {
      page: 1,
      limit: 10,
      sort: "installmentDueDate:ASC",
      search: this.borrowerCpf || "",
      groupId: null,
      companyId: null,
      loanInstallmentDueDateStart: null,
      loanInstallmentDueDateEnd: null,
      startedSimulationDateStart: null,
      startedSimulationDateEnd: null,
      requestDateStart: null,
      requestDateEnd: null,
      statusGroupId: 2
    };
    this.reports = {
      items: [],
      total: 0
    };
  }

  get companiesListFilteredByGroup(): Array<Company> {
    if (this.filters.groupId) {
      return this.companiesList.filter(
        (company) => company.groupId === this.filters.groupId
      );
    }
    return this.companiesList;
  }

  created(): void {
    this.loadFilters();
  }

  mounted(): void {
    this.loadReports();
  }

  loadFromStart() {
    this.filters.page = 1;
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.loading = true;

    const [loanInstallmentsReportError, loanInstallmentsReportData] =
      await this.loanService.getLoanInstallmentsReport(this.filters);

    if (loanInstallmentsReportError) {
      this.$notify({
        type: "error",
        text: "Ocorreu um erro ao buscar os dados do relatório"
      });
    } else if (loanInstallmentsReportData) {
      this.reports = loanInstallmentsReportData;
    }

    this.loading = false;
  }

  async loadFilters(): Promise<void> {
    const [groupsError, groupsData] = await this.companyService.listGroups({
      page: 1,
      limit: 100000,
      sort: "name:ASC",
      groupName: "",
      companyNameOrCnpj: "",
      adminEmail: "",
      adminPhone: ""
    });
    if (groupsData) {
      this.groupsList = groupsData.items;
    }
    const [companiesError, companiesData] =
      await this.companyService.listCompanies({
        page: 1,
        limit: 100000,
        sort: "name:ASC",
        search: ""
      });
    if (companiesData) {
      this.companiesList = companiesData.items;
    }

    const [loanStatusListError, loanStatusList] =
      await this.loanService.getStatusList();

    if (loanStatusListError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de status para o filtro."
      });
    }
    if (loanStatusList) {
      this.loanStatusList = loanStatusList;
    }

    const [loanInstallmentsStatusListError, loanInstallmentsStatusList] =
      await this.loanService.getLoanInstallmentsStatusList();

    if (loanInstallmentsStatusListError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de status das parcelas para o filtro."
      });
    }
    if (loanInstallmentsStatusList) {
      this.loanInstallmentStatusList = loanInstallmentsStatusList;
    }

    this.loadStatusGroupList();
  }

  async loadStatusGroupList(): Promise<void> {
    const [statusGroupListError, statusGroupList] =
      await this.loanService.getStatusGroupList();
    this.statusGroupList = statusGroupList;

    if (statusGroupListError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de grupos de status para o filtro."
      });
    }
  }

  async downloadXls(): Promise<void> {
    this.loadingXls = true;
    const [error, data] =
      await this.loanService.generateLoanInstallmentsLoansReportXls(
        this.filters
      );
    if (!error) {
      this.$notify({
        type: "success",
        title: "Relatório solicitado",
        text: 'O XLS ficará disponível em "Meus Relatórios"'
      });
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loadingXls = false;
  }

  onOptionsChange(tableOptions: DataOptions): DataOptions {
    this.filters.page = tableOptions.page;
    this.filters.limit = tableOptions.itemsPerPage;
    this.filters.sort = this.formatSort(
      tableOptions.sortBy,
      tableOptions.sortDesc
    );
    this.loadData();

    return tableOptions;
  }

  @Watch("dateFilterType")
  onDateFilterTypeChange(): void {
    this.filters.loanInstallmentDueDateStart = null;
    this.filters.loanInstallmentDueDateEnd = null;
    this.filters.startedSimulationDateStart = null;
    this.filters.startedSimulationDateEnd = null;
    this.filters.requestDateStart = null;
    this.filters.requestDateEnd = null;
  }

  formatSort(sortBy: Array<string>, sortDesc: Array<boolean>): string {
    const attr = sortBy[0] ?? "installmentDueDate";
    const order = sortDesc[0] ? "ASC" : "DESC";

    return `${attr}:${order}`;
  }

  formatDate(date: string) {
    return dayjs(date).format("DD/MM/YYYY");
  }

  async loadReports(): Promise<void> {
    this.loadActiveLoansReports();
    this.loadLoanInstallmentsStatusReport();
  }

  async loadActiveLoansReports(): Promise<void> {
    this.dashboardLastGroupFilter = this.filters.groupId || null;
    this.creditValueSumByMonthReport = null;
    this.installmentValueSumByMonthReport = null;
    this.installmentDebitBalanceValueSumByMonthReport = null;
    this.expandedRegularReport = null;
    this.expandedRegularReportTitle = null;
    this.formatExpandedRegularReportAsCurrency = null;
    this.dataByMonthTab = 24 - 1;

    const [activeLoansReportError, activeLoansReportsData] =
      await this.loanService.loansWithDetailDashboardActiveLoansByMonthReport({
        groupId: this.filters.groupId
      });
    if (!activeLoansReportError && activeLoansReportsData) {
      const {
        creditValueSumByMonth,
        installmentValueSumByMonth,
        installmentDebitBalanceValueSumByMonth
      } = activeLoansReportsData;

      this._addPercentageByMonthToData(creditValueSumByMonth!);
      this._addPercentageByMonthToData(installmentValueSumByMonth!);
      this._addPercentageByMonthToData(installmentDebitBalanceValueSumByMonth!);

      this.creditValueSumByMonthReport = creditValueSumByMonth!;
      this.installmentValueSumByMonthReport = installmentValueSumByMonth!;
      this.installmentDebitBalanceValueSumByMonthReport =
        installmentDebitBalanceValueSumByMonth!;
    }
  }

  async loadLoanInstallmentsStatusReport(): Promise<void> {
    this.installmentStatusByMonthReport = null;
    this.installmentStatusByMonthChartData = null;
    this.expandedInstallmentStatusReport = null;
    this.expandedInstallmentStatusReportTitle = null;

    const [
      loanInstallmentsStatusByMonthReportError,
      loanInstallmentsStatusByMonthReportData
    ] =
      await this.loanService.loansWithDetailDashboardLoanInstallmentsStatusByMonthReport(
        {
          groupId: this.filters.groupId
        }
      );
    if (
      !loanInstallmentsStatusByMonthReportError &&
      loanInstallmentsStatusByMonthReportData
    ) {
      const loanInstallmentsStatusByMonthReport =
        loanInstallmentsStatusByMonthReportData!.map((month) => ({
          ...month,
          value: month.value.map((status, statusIndex) => ({
            ...status,
            backgroundColor: this.installmentStatusBackgroundColors[statusIndex]
          }))
        }));
      this.installmentStatusByMonthReport = loanInstallmentsStatusByMonthReport;

      this.installmentStatusByMonthChartData =
        this._convertInstallmentStatusDataToDoughnutChartData(
          loanInstallmentsStatusByMonthReport[2 - 1]
        );
    }
  }

  _addPercentageByMonthToData(data: MonthData[]) {
    // calculate percentage by month
    const biggestMonthValue = Math.max(
      ...data.map((data: MonthData) => data.value || 0)
    );
    data.forEach((data: MonthData) => {
      data.calculatedPercentage =
        biggestMonthValue > 0 ? (data.value * 100) / biggestMonthValue : 0;
    });
  }

  _convertInstallmentStatusDataToDoughnutChartData(
    data: ExtendedLoanInstallmentsStatusReportResponse[0]
  ) {
    const nonEmptyData = data.value.filter((v) => v.installmentValueSum > 0);
    const labels = nonEmptyData.map((v) => v.statusReadableName);
    const dataSetsData = labels.map(
      (label) =>
        nonEmptyData.find((v) => v.statusReadableName === label)!
          .installmentValueSum || 0
    );
    const dataSetsBackgroundColor = nonEmptyData.map((v) => v.backgroundColor);
    return {
      labels,
      datasets: [
        {
          data: dataSetsData,
          backgroundColor: dataSetsBackgroundColor
        }
      ]
    };
  }

  onClickRegularReport({
    report,
    title,
    subtitle,
    formatAsCurrency
  }: {
    report: MonthData[];
    title: string;
    subtitle: string;
    formatAsCurrency: boolean;
  }): void {
    // Clear installment status report
    this.expandedInstallmentStatusReport = null;
    this.expandedInstallmentStatusReportTitle = null;

    if (
      !this.expandedRegularReport ||
      (this.expandedRegularReport && this.expandedRegularReport !== report)
    ) {
      this.expandedRegularReport = report;
      this.expandedRegularReportTitle = title;
      this.expandedRegularReportSubtitle = subtitle;
      this.formatExpandedRegularReportAsCurrency = formatAsCurrency;
    } else {
      this.expandedRegularReport = null;
      this.expandedRegularReportTitle = null;
      this.expandedRegularReportSubtitle = null;
    }
  }

  onClickStatusReport({
    report,
    title
  }: {
    report: ExtendedLoanInstallmentsStatusReportResponse;
    title: string;
  }): void {
    // Clear regular report
    this.expandedRegularReport = null;
    this.expandedRegularReportTitle = null;
    this.expandedRegularReportSubtitle = null;

    if (
      !this.expandedInstallmentStatusReport ||
      (this.expandedInstallmentStatusReport &&
        this.expandedInstallmentStatusReport !== report)
    ) {
      this.expandedInstallmentStatusReport = report;
      this.expandedInstallmentStatusReportTitle = title;
    } else {
      this.expandedInstallmentStatusReport = null;
      this.expandedInstallmentStatusReportTitle = null;
    }
  }

  get activeMonth(): MonthData | null {
    if (this.expandedRegularReport) {
      return this.expandedRegularReport[this.dataByMonthTab];
    }

    return null;
  }

  get dashboardFilterNotice(): string {
    if (!this.dashboardLastGroupFilter) {
      return "As estatísticas abaixo são referentes a todas as empresas";
    } else {
      return `As estatísticas abaixo são referentes às empresas do grupo: ${
        this.groupsList.find((g) => g.id === this.dashboardLastGroupFilter)
          ?.name || "-"
      }`;
    }
  }
}
