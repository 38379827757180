
import { Vue, Component, Watch } from "vue-property-decorator";
import dayjs from "@/plugins/day-js";
import Page from "@/components/core/dashboard/Page.vue";
import { DataOptions, DataTableHeader } from "vuetify";
import PartnerService, {
  PartnerList,
  PartnerLoanCommissionList,
  PartnerLoanCommissionParams
} from "@/services/partner-service";
import PartnerCommissionModal from "@/components/partners/PartnerCommissionModal.vue";
import DatePicker from "@/components/DatePicker.vue";

import { format as formatCNPJ } from "@/utils/cnpj";
import { format as formatCPF } from "@/utils/cpf";

import Modal from "@/components/Modal.vue";
import PartnerLoanCommissionStatus, {
  partnerLoanCommissionStatusList
} from "@/types/partner-loan-commission-status";
import PartnerLoanComission from "@/types/partner-loan-commission-batch-register";
import debounce from "debounce";
import Company from "@/types/company";
import CompanyService from "@/services/company-service";

type CompanyList = { items: Array<Company>; total: number };
@Component({
  components: {
    PartnerCommissionModal,
    Modal,
    Page,
    DatePicker
  }
})
export default class PartnersCommissions extends Vue {
  formatCPF = formatCPF;
  formatCNPJ = formatCNPJ;
  options: DataOptions;
  service: PartnerService;
  companyService: CompanyService;
  partnerLoanCommissions: PartnerLoanCommissionList;
  headers: Array<DataTableHeader>;
  activePartnerLoanComission?: PartnerLoanComission | null = null;
  loading = false;
  loadingPartners = false;
  loadingCompanies = false;
  filters: PartnerLoanCommissionParams = {
    initialContractingDate: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
    finalContractingDate: dayjs().format("YYYY-MM-DD")
  };
  statusList: PartnerLoanCommissionStatus[] = partnerLoanCommissionStatusList;
  dateFilterTypes: Array<string> = [
    "Data de início da contratação",
    "Data da contratação"
  ];
  dateFilterType: string | null = "Data da contratação";
  partners: PartnerList;
  partnerSearch: string | null = null;
  companies: CompanyList;
  companySearch: string | null = null;
  loadingXls: boolean = false;

  constructor() {
    super();
    this.service = PartnerService.getInstance();
    this.companyService = CompanyService.getInstance();
    this.headers = [
      { text: "Empresa", value: "companyName", sortable: false },
      { text: "Parceiro", value: "partner", sortable: false },
      { text: "Contrato", value: "loanId", sortable: false },
      {
        text: "Data Contratação",
        value: "loanContractingDate",
        sortable: false
      },
      { text: "Valor", value: "loanAmount", sortable: false },
      {
        text: "Comissão",
        value: "commissionPercentage",
        sortable: false
      },
      {
        text: "R$ Comissão",
        value: "commissionAmount",
        sortable: false
      },
      {
        text: "Status",
        value: "statusId",
        sortable: false
      }
    ];
    this.partnerLoanCommissions = {
      data: [],
      total: 0
    };
    this.partners = {
      data: [],
      total: 0
    };
    this.companies = {
      items: [],
      total: 0
    };
    this.options = {} as DataOptions;
  }

  async created(): Promise<void> {
    await this.list();
  }

  async list(): Promise<PartnerLoanCommissionList> {
    this.loading = true;
    const [error, partnerLoanCommissions] =
      await this.service.partnerLoanCommissions(this.filters);

    this.loading = false;
    if (error || !partnerLoanCommissions) {
      this.$notify({
        type: "error",
        text: "Não foi possível obter a lista de comissões"
      });
    } else {
      this.partnerLoanCommissions = partnerLoanCommissions;
    }

    return this.partnerLoanCommissions;
  }

  searchPartners = debounce(async () => {
    await this.fetchPartners();
  }, 700);

  async fetchPartners(): Promise<PartnerList> {
    if (this.hasPermissions(["LISTAR_PARCEIROS"])) {
      this.loadingPartners = true;
      const [error, partners] = await this.service.listPartners({
        page: 1,
        limit: 10,
        name: this.partnerSearch || ""
      });

      this.loadingPartners = false;

      if (error || !partners) {
        this.$notify({
          type: "error",
          text: "Não foi possível obter a lista de parceiros"
        });
      } else {
        this.partners = partners;
      }
    }
    return this.partners;
  }

  searchCompanies = debounce(async () => {
    await this.fetchCompanies();
  }, 700);

  async fetchCompanies(): Promise<CompanyList> {
    if (this.hasPermissions(["LISTAR_EMPRESAS"])) {
      this.loadingCompanies = true;
      const [error, companies] = await this.companyService.listCompanies({
        page: 1,
        limit: 10,
        search: this.companySearch || ""
      });

      this.loadingCompanies = false;

      if (error || !companies) {
        this.$notify({
          type: "error",
          text: "Não foi possível obter a lista de empresas"
        });
      } else {
        this.companies = companies;
      }
    }
    return this.companies;
  }

  formatDate(date: string) {
    return dayjs(date).format("DD/MM/YYYY");
  }

  applyFilters(): void {
    this.filters.page = 1;
    this.filters.limit = 10;
    this.list();
  }

  closeModal(): void {
    this.activePartnerLoanComission = null;
    this.$emit("close");
  }

  async downloadXls(): Promise<void> {
    this.loadingXls = true;
    const filters = { ...this.filters };

    const [error, data] = await this.service.exportPartnerLoanCommissions(
      filters
    );

    if (error || !data) {
      return this.$notify({
        type: "error",
        text: "Não foi possível realizar o download do relatório de comissões"
      });
    }

    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `comissoes_${dayjs().format("DD/MM/YYYY HH:mm")}.xlsx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);

    this.loadingXls = false;
  }

  @Watch("options")
  onOptionsChanged(val: DataOptions) {
    const { page, itemsPerPage } = val;
    this.filters.page = page;
    this.filters.limit = itemsPerPage === -1 ? 0 : itemsPerPage;
    this.list();
  }

  formatStatus(statusId: number): string | undefined {
    if (this.statusList?.length) {
      const statusItem = this.statusList.find((item) => item.id === statusId);

      if (statusItem) {
        return statusItem.description;
      }
    }
  }

  @Watch("dateFilterType")
  onDateFilterTypeChange(): void {
    this.filters.initialCreatedAtDate = undefined;
    this.filters.finalCreatedAtDate = undefined;
    this.filters.initialContractingDate = undefined;
    this.filters.finalContractingDate = undefined;
  }
}
