
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Modal extends Vue {
  @Prop() readonly percentage!: number | null;
  @Prop() readonly period!: string;
  @Prop() readonly align?: string;

  periodLabels: { [key: string]: string } = {
    DAY: "vs. dia anterior",
    MONTH: "vs. último mês",
    SEMESTER: "vs. 6 meses anteriores",
    YEAR: "vs. o ano anterior"
  };

  get percentageValue(): number {
    if (this.percentage === null || this.percentage === undefined) return 0;
    if (this.percentage >= 0) return this.percentage;
    return this.percentage * -1;
  }

  get percentageClass(): string {
    if (
      this.percentage === null ||
      this.percentage === undefined ||
      this.percentage === 0
    )
      return "grey--text";
    if (this.percentage >= 0) return "success--text";
    return "error--text";
  }

  get percentageColor(): string {
    if (
      this.percentage === null ||
      this.percentage === undefined ||
      this.percentage === 0
    )
      return "grey";
    if (this.percentage >= 0) return "success";
    return "error";
  }

  get percentageIcon(): string {
    if (
      this.percentage === null ||
      this.percentage === undefined ||
      this.percentage === 0
    )
      return "";
    if (this.percentage >= 0) return "mdi-arrow-up";
    return "mdi-arrow-down";
  }

  get periodLabel(): string {
    return this.periodLabels[this.period];
  }
}
