
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import LoanService from "@/services/loan-service";
import Modal from "@/components/Modal.vue";
import DatePicker from "@/components/DatePicker.vue";
import LoanStatusGroup from "@/types/loan-status-group";
import { format as formatCNPJ } from "@/utils/cnpj";
import { ReconciliateDetailsReportsFilters } from "@/services/loan-service/types/management-reports";
import CompanyService, {
  ListCompaniesResponse
} from "@/services/company-service";
import { format } from "@/utils/cpf";
import Company from "@/types/company";
import dayjs from "dayjs";
import formatCurrency from "@/utils/formatCurrency";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import { BatchStatusEnum } from "@/types/batch-status";
import { NonReconciliationMotiveEnum } from "@/types/non-reconciliation-motive";

@Component({
  components: {
    Modal,
    DatePicker
  }
})
export default class ReconciliateDetailsReport extends Vue {
  @Prop() readonly remittanceId?: number;
  @Prop() readonly contract?: string;
  loanService: LoanService;
  batchRegisters: any;
  filters: ReconciliateDetailsReportsFilters;
  selectedCompany: Partial<Company> | null = null;
  companyService: CompanyService;
  headers: Array<DataTableHeader>;
  companiesList: ListCompaniesResponse = {
    items: [],
    total: 0
  };
  formatCNPJ = formatCNPJ;
  formatCPF = format;
  formatCurrency = formatCurrency;
  dateFilterTypes: Array<string> = [
    "Geração Lote",
    "Vencimento Lote",
    "Limite Lote"
  ];
  dateFilterType = "Geração Lote";
  fundTypes: Array<{
    name: string;
    id: number;
  }>;
  typeOfOcorrences: Array<{ id: number; name: string }>;
  batchStatus: Array<{ id: number; name: string }>;
  nonReconciliationMotives: Array<{ id: number; name: string }>;
  partiallyPaid: Array<{ value: boolean; name: string }>;
  conciliated: Array<{ value: boolean; name: string }>;
  loading = true;
  loadingXls = false;
  statusGroupList: LoanStatusGroup[] | null = null;
  fileManagerUrl: string;

  constructor() {
    super();
    this.loanService = LoanService.getInstance();
    this.companyService = CompanyService.getInstance();
    this.headers = [
      { text: "Lote", value: "batch.id", sortable: true },
      { text: "Data Geração", value: "batch.generationDate", sortable: false },
      {
        text: "Empresa",
        value: "batch.companyName",
        sortable: true
      },
      {
        text: "CPF Tomador",
        value: "loanInstallment.loan.borrowerCpf",
        sortable: false
      },
      {
        text: "Parcela/Prazo",
        value: "loanInstallment.number",
        sortable: false
      },
      {
        text: "Repasse",
        value: "reconciliationValue",
        sortable: false
      },
      {
        text: "Consigna",
        value: "reconciliate",
        sortable: false
      },
      {
        text: "Motivo",
        value: "nonReconciliationMotiveId",
        sortable: false
      },
      {
        text: "Verbas Rescisórias",
        value: "severancePayValue",
        sortable: false
      },
      {
        text: "Contrato",
        value: "loanInstallment.loan.proposalNumber",
        sortable: false
      },
      { text: "Proposta", value: "loanInstallment.loan.id", sortable: false }
    ];
    this.batchStatus = [
      {
        name: `${BatchStatusEnum.OPEN.description}`,
        id: BatchStatusEnum.OPEN.id
      },
      {
        name: `${BatchStatusEnum.CLOSED.description}`,
        id: BatchStatusEnum.CLOSED.id
      },
      {
        name: `${BatchStatusEnum.EDITING.description}`,
        id: BatchStatusEnum.EDITING.id
      }
    ];
    this.nonReconciliationMotives = [
      {
        name: NonReconciliationMotiveEnum.EMPLOYEE_AWAY.readableName,
        id: NonReconciliationMotiveEnum.EMPLOYEE_AWAY.id
      },
      {
        name: NonReconciliationMotiveEnum.EMPLOYEE_WITHOUT_MARGIN_BASE
          .readableName,
        id: NonReconciliationMotiveEnum.EMPLOYEE_WITHOUT_MARGIN_BASE.id
      },
      {
        name: NonReconciliationMotiveEnum.FIRED_EMPLOYEE.readableName,
        id: NonReconciliationMotiveEnum.FIRED_EMPLOYEE.id
      },
      {
        name: NonReconciliationMotiveEnum.GENERATED_AFTER_CONCILIATION
          .readableName,
        id: NonReconciliationMotiveEnum.GENERATED_AFTER_CONCILIATION.id
      }
    ];
    this.partiallyPaid = [
      {
        name: "Sim",
        value: true
      },
      {
        name: "Não",
        value: false
      }
    ];
    this.conciliated = [
      {
        name: "Sim",
        value: true
      },
      {
        name: "Não",
        value: false
      }
    ];
    this.filters = {
      page: 1,
      limit: 10,
      sort: "id:ASC",
      batch: null,
      batchGenerationDateStart: null,
      batchGenerationDateEnd: null,
      batchDueDateStart: null,
      batchDueDateEnd: null,
      batchLimitDateStart: null,
      batchLimitDateEnd: null,
      batchStatusId: null,
      conciliated: null,
      nonReconciliationMotiveId: null,
      partiallyPaid: null,
      propose: null,
      companyId: null,
      cnpj: null,
      borrowerCpf: null,
      contract: null
    };
    this.batchRegisters = {
      items: [],
      total: 0
    };
    this.fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL || "";
  }

  created(): void {
    this.loadFilters();
  }

  async filterFromStart(): Promise<void> {
    this.filters.page = 1;
    this.loadReconciliateDetails();
  }

  async loadReconciliateDetails(): Promise<void> {
    this.loading = true;

    const [ReconciliateReportError, ReconciliateReportData] =
      await this.loanService.getReconciliateDetailsReports(this.filters);

    if (ReconciliateReportError) {
      this.$notify({
        type: "error",
        text: "Ocorreu um erro ao buscar os dados do relatório"
      });
    } else if (ReconciliateReportData) {
      this.batchRegisters.items = ReconciliateReportData.items;
      this.batchRegisters.total = ReconciliateReportData.total;
    }
    this.loading = false;
  }

  async downloadXls(): Promise<void> {
    this.loadingXls = true;
    const [error, data] =
      await this.loanService.generateReconciliateDetailsReportXls(this.filters);
    if (!error) {
      this.$notify({
        type: "success",
        title: "Relatório solicitado",
        text: 'O XLS ficará disponível em "Meus Relatórios"'
      });
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loadingXls = false;
  }

  onOptionsChange(tableOptions: DataOptions): DataOptions {
    this.filters.page = tableOptions.page;
    this.filters.limit = tableOptions.itemsPerPage;
    this.filters.sort = this.formatSort(
      tableOptions.sortBy,
      tableOptions.sortDesc
    );
    this.loadReconciliateDetails();

    return tableOptions;
  }

  formatSort(sortBy: Array<string>, sortDesc: Array<boolean>): string {
    const attr = sortBy[0] ? sortBy[0].split(".")[1] : "id";
    const order = sortDesc[0] ? "DESC" : "ASC";

    return `${attr}:${order}`;
  }

  async loadFilters(): Promise<void> {
    const [companiesError, companiesData] =
      await this.companyService.listCompanies({
        page: 1,
        limit: 100000,
        sort: "name:ASC",
        search: ""
      });
    if (companiesError) {
      this.$notify({
        type: "error",
        text: "Ocorreu um erro ao buscar os dados do relatório"
      });
    } else if (companiesData) {
      this.companiesList = companiesData;
    }
  }

  updateCompanyFilters(): void {
    if (this.selectedCompany) {
      this.filters.companyId = this.selectedCompany.id;
      this.filters.cnpj = this.selectedCompany.cnpj;
    } else {
      this.filters.companyId = null;
      this.filters.cnpj = null;
    }
  }

  getReadableName(id) {
    const motive = this.nonReconciliationMotives.find(
      (motive) => motive.id === id
    );
    return motive ? motive.name : "-";
  }

  @Watch("dateFilterType")
  onDateFilterTypeChange(): void {
    this.filters.batchGenerationDateStart = null;
    this.filters.batchGenerationDateEnd = null;
    this.filters.batchDueDateStart = null;
    this.filters.batchDueDateEnd = null;
    this.filters.batchLimitDateStart = null;
    this.filters.batchLimitDateEnd = null;
  }

  formatDate(date: string): string {
    if (!date) return "";
    const [data] = date.split("T");
    const [ano, mes, dia] = data.split("-");
    const dataFormatada = `${dia}/${mes}/${ano}`;
    return dataFormatada;
  }
}
