
import { Vue, Component, Watch } from "vue-property-decorator";
import Page from "@/components/core/dashboard/Page.vue";
import CreditPolicy, {
  CreditPolicyTypeEnum,
  CreditPolicyStatusEnum
} from "@/types/credit-policy";
import { DataTableHeader } from "vuetify";
import CreditPolicyService from "@/services/credit-policy-service";
import CreditPolicyManageModal from "@/components/credit-policies/CreditPolicyManageModal.vue";
import CreditPolicyDeleteModal from "@/components/credit-policies/CreditPolicyDeleteModal.vue";
import Modal from "@/components/Modal.vue";
import getErrorMessageFromApiError from "../utils/getErrorMessageFromApiError";
import Segment from "../types/segment";

@Component({
  components: {
    CreditPolicyManageModal,
    CreditPolicyDeleteModal,
    Modal,
    Page
  }
})
export default class CreditPoliciesManage extends Vue {
  CreditPolicyTypeEnum = CreditPolicyTypeEnum;
  service: CreditPolicyService;
  segments: Segment[] = [];
  allCreditPolicies: CreditPolicy[] = [];
  bySegmentCreditPolicies: CreditPolicy[] = [];
  specialCreditPolicies: CreditPolicy[] = [];
  allHeaders = [
    { text: "Identificador", value: "identifier", sortable: false },
    { text: "Segmento", value: "segment.name", sortable: false },
    { text: "Descrição", value: "description", sortable: false },
    { text: "Status", value: "status", sortable: false },
    {
      text: "Ações",
      value: "actions",
      sortable: false,
      cellClass: "text-end",
      class: "text-end"
    }
  ];
  headers: Array<DataTableHeader> = [];
  addCreditPolicy = false;
  editCreditPolicy?: CreditPolicy | null = null;
  deleteCreditPolicy?: CreditPolicy | null = null;
  loading = false;
  statusOptions = Object.values(CreditPolicyStatusEnum).map((status) => ({
    id: status.id,
    name: status.description
  }));
  filters = {
    typeId: CreditPolicyTypeEnum.BY_SEGMENT.id,
    statusId: null as number | null,
    segmentId: null as number | null,
    identifier: ""
  };
  page: number = 1;
  itemsPerPage: number = 50;

  constructor() {
    super();
    this.service = CreditPolicyService.getInstance();
    this.headers = [...this.allHeaders];
    this.allCreditPolicies = [];
  }

  @Watch("filters.typeId")
  onTypeIdChange(): void {
    this.filters.segmentId = null;

    if (this.filters.typeId === CreditPolicyTypeEnum.BY_SEGMENT.id) {
      this.headers = [...this.allHeaders];
    } else {
      this.headers = this.allHeaders.filter(
        (header) => header.value !== "segment.name"
      );
    }
  }

  get filteredCreditPolicies(): CreditPolicy[] {
    let filteredPolicies = [...this.allCreditPolicies];

    if (this.filters.typeId === CreditPolicyTypeEnum.BY_SEGMENT.id) {
      filteredPolicies = [...this.bySegmentCreditPolicies];
    } else if (this.filters.typeId === CreditPolicyTypeEnum.SPECIAL.id) {
      filteredPolicies = [...this.specialCreditPolicies];
    }

    if (this.filters.statusId !== null) {
      filteredPolicies = filteredPolicies.filter(
        (cp) => cp.statusId === this.filters.statusId
      );
    }

    if (
      this.filters.typeId === CreditPolicyTypeEnum.BY_SEGMENT.id &&
      this.filters.segmentId !== null
    ) {
      filteredPolicies = filteredPolicies.filter(
        (cp) => cp.segmentId === this.filters.segmentId
      );
    }

    if (this.filters.identifier) {
      const lowerCaseIdentifier = this.filters.identifier.toLowerCase();
      filteredPolicies = filteredPolicies.filter((cp) =>
        cp.identifier.toLowerCase().includes(lowerCaseIdentifier)
      );
    }

    return filteredPolicies;
  }

  async created(): Promise<void> {
    this.fetchSegments();
    this.fetchCreditPolicies();
  }

  async fetchSegments(): Promise<void> {
    this.loading = true;
    const [error, segments] = await this.service.listSegments();
    this.loading = false;
    if (error || !segments) {
      this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    } else {
      this.segments = segments;
    }
  }

  async fetchCreditPolicies(): Promise<CreditPolicy[]> {
    this.loading = true;
    const [error, creditPolicies] = await this.service.listCreditPolicies();
    if (error || !creditPolicies) {
      this.$notify({
        type: "error",
        text: "Não foi possível obter a lista de políticas de crédito"
      });
    } else {
      this.allCreditPolicies = creditPolicies;
      this.bySegmentCreditPolicies = creditPolicies.filter(
        (cp) => cp.typeId === CreditPolicyTypeEnum.BY_SEGMENT.id
      );
      this.specialCreditPolicies = creditPolicies.filter(
        (cp) => cp.typeId === CreditPolicyTypeEnum.SPECIAL.id
      );
    }
    this.loading = false;
    return this.allCreditPolicies;
  }

  async remove(creditPolicyId: number): Promise<void> {
    this.closeModal();
    this.loading = true;
    const [error] = await this.service.removeCreditPolicy(creditPolicyId);
    if (error) {
      this.$notify({
        type: "error",
        text: "Não foi possível remover a política de crédito"
      });
    }
    this.loading = false;

    this.fetchCreditPolicies();
  }

  closeModal(): void {
    this.addCreditPolicy = false;
    this.editCreditPolicy = null;
    this.deleteCreditPolicy = null;
    this.$emit("close");
  }
}
