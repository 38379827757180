
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import CompanyService from "@/services/company-service";
import LoanService from "@/services/loan-service";
import Modal from "@/components/Modal.vue";
import DatePicker from "@/components/DatePicker.vue";
import Group from "@/types/group";
import Partner from "@/types/partner";
import { format as formatDocument } from "@/utils/cpfOrCnpj";
import { format as formatCNPJ } from "@/utils/cnpj";
import BorrowersLoansReport from "@/components/management-reports/BorrowersLoansReport.vue";
import {
  CompaniesLoansReportData,
  CompaniesLoansReportFilters
} from "@/services/loan-service/types/management-reports";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import LoanStatusGroup from "@/types/loan-status-group";
import PartnerService from "../../services/partner-service";
import { MonthData } from "../../types/dashboard-reports";
import { MonthShortAndLongForm } from "@/utils/months";
import formatCurrency from "@/utils/formatCurrency";
import calculateDiffInPercentage from "@/utils/calculate-diff-in-percentage";
import DashboardReportPercentage from "@/components/DashboardReportPercentage.vue";
import SafetyService from "@/services/safety-service";
import User from "@/types/user";

@Component({
  components: {
    Modal,
    DatePicker,
    BorrowersLoansReport,
    DashboardReportPercentage
  }
})
export default class CompaniesLoansReport extends Vue {
  @Prop() readonly groupId?: number;
  formatCurrency = (value: number) => {
    if (value >= 10000) return formatCurrency(value, "compact");
    return formatCurrency(value);
  };
  calculateDiffInPercentage = calculateDiffInPercentage;
  formatDocument = formatDocument;
  formatCNPJ = formatCNPJ;
  MonthShortAndLongForm = MonthShortAndLongForm;
  companyId: number | null = null;
  groupsList: Array<Group>;
  responsableCommercialList: Array<User> | null = null;
  safetyService: SafetyService;
  partnersList: Array<Partner>;
  companyService: CompanyService;
  loanService: LoanService;
  partnerService: PartnerService;
  reports: CompaniesLoansReportData;
  filters: CompaniesLoansReportFilters;
  headers: Array<DataTableHeader>;
  loading = true;
  loadingXls = false;
  dateFilterTypes: Array<string> = [
    "Data de contratação",
    "Data de início da simulação",
    "Data de vencimento da parcela"
  ];
  dateFilterType = "Data de contratação";
  statusGroupList: LoanStatusGroup[] | null = null;
  dashboardLastGroupFilter: number | null = null;
  activeCompaniesReport: MonthData[] | null = null;
  loansCountByMonthReport: MonthData[] | null = null;
  loansTotalValueSumByMonthReport: MonthData[] | null = null;
  borrowersCountByMonthReport: MonthData[] | null = null;
  dataByMonthTab: number = 0;
  expandedReport: MonthData[] | null = null;
  expandedReportTitle: string | null = null;
  expandedReportSubtitle: string | null = null;
  formatExpandedReportAsCurrency: boolean | null = null;

  constructor() {
    super();
    this.companyService = CompanyService.getInstance();
    this.loanService = LoanService.getInstance();
    this.partnerService = PartnerService.getInstance();
    this.safetyService = SafetyService.getInstance();
    this.responsableCommercialList = [];
    this.groupsList = [];
    this.partnersList = [];
    this.headers = [
      { text: "Empresa", value: "companyName", sortable: false },
      { text: "CNPJ", value: "companyCnpj", sortable: false },
      { text: "Total Contratos", value: "loansCount", sortable: false },
      {
        text: "Valor total Contratos",
        value: "loansTotalValue",
        sortable: false
      },
      {
        text: "Total Colaboradores",
        value: "loansCountBorrowers",
        sortable: false
      },
      { text: "Parceiro", value: "partnerId", sortable: false },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        cellClass: "text-end",
        class: "text-end"
      }
    ];
    this.filters = {
      page: 1,
      limit: 10,
      sort: "companyName:ASC",
      search: "",
      groupId: this.groupId || null,
      subpartnerId: null,
      partnerIds: null,
      loanInstallmentDueDateStart: null,
      loanInstallmentDueDateEnd: null,
      startedSimulationDateStart: null,
      startedSimulationDateEnd: null,
      requestDateStart: null,
      requestDateEnd: null,
      statusGroupId: 2,
      responsibleCommercialId: null
    };
    this.reports = {
      items: [],
      total: 0
    };
  }

  created(): void {
    this.loadFilters();
  }

  mounted(): void {
    this.loadReports();
  }

  loadFromStart() {
    this.filters.page = 1;
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.loading = true;

    const [companiesLoansReportError, companiesLoansReportData] =
      await this.loanService.getCompaniesLoansReport(this.filters);

    if (companiesLoansReportError) {
      this.$notify({
        type: "error",
        text: "Ocorreu um erro ao buscar os dados do relatório"
      });
    } else if (companiesLoansReportData) {
      this.reports = companiesLoansReportData;
    }

    this.loading = false;

    if (this.filters.groupId !== this.dashboardLastGroupFilter) {
      // If the filtered group changed, generate dashboard again
      this.loadReports();
    }
  }

  async loadFilters(): Promise<void> {
    const [error, groupsData] = await this.companyService.listGroups({
      page: 1,
      limit: 100000,
      sort: "name:ASC",
      groupName: "",
      companyNameOrCnpj: "",
      adminEmail: "",
      adminPhone: ""
    });
    if (groupsData) {
      this.groupsList = groupsData.items;
    }

    this.loadPartnersList();
    this.loadStatusGroupList();
    this.loadCommercials();
  }

  async loadPartnersList(): Promise<void> {
    const [partnersError, partnersData] =
      await this.partnerService.listPartners({
        page: 1,
        limit: 100000
      });

    if (!partnersData || partnersError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de parceiros para o filtro."
      });
    } else {
      this.partnersList = partnersData.data;
    }
  }

  async loadCommercials(): Promise<void> {
    const [userListError, comercialList] = await this.safetyService.listUsers({
      page: 1,
      limit: -1,
      types: ["ADMIN_GOOROO"]
    });

    if (userListError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar os usuários do comercial."
      });
    }

    this.responsableCommercialList = comercialList.items;
  }

  async loadStatusGroupList(): Promise<void> {
    const [statusGroupListError, statusGroupList] =
      await this.loanService.getStatusGroupList();
    this.statusGroupList = statusGroupList;

    if (statusGroupListError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de grupos de status para o filtro."
      });
    }
  }

  async downloadXls(): Promise<void> {
    this.loadingXls = true;
    const [error, data] =
      await this.loanService.generateCompaniesLoansReportXls(this.filters);
    if (!error) {
      window.open(
        process.env.VUE_APP_LOAN_URL! +
          "/reports/download-xls?token=" +
          data!.token,
        "_blank"
      );
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loadingXls = false;
  }

  onOptionsChange(tableOptions: DataOptions): DataOptions {
    this.filters.page = tableOptions.page;
    this.filters.limit = tableOptions.itemsPerPage;
    this.filters.sort = this.formatSort(
      tableOptions.sortBy,
      tableOptions.sortDesc
    );
    this.loadData();

    return tableOptions;
  }

  @Watch("dateFilterType")
  onDateFilterTypeChange(): void {
    this.filters.loanInstallmentDueDateStart = null;
    this.filters.loanInstallmentDueDateEnd = null;
    this.filters.startedSimulationDateStart = null;
    this.filters.startedSimulationDateEnd = null;
    this.filters.requestDateStart = null;
    this.filters.requestDateEnd = null;
  }

  @Watch("responsibleCommercialId")
  updateReponsabelCommercialFilter(): void {
    this.filters.responsibleCommercialId =
      this.filters.responsibleCommercialId || null;
  }

  formatSort(sortBy: Array<string>, sortDesc: Array<boolean>): string {
    const attr = sortBy[0] ?? "companyName";
    const order = sortDesc[0] ? "ASC" : "DESC";

    return `${attr}:${order}`;
  }

  setPartnerFilter(v: string[]): void {
    this.filters.partnerIds = v.map((x) => Number(x));
    this.filters.subpartnerId = null;
  }

  async loadReports(): Promise<void> {
    this.dashboardLastGroupFilter = this.filters.groupId || null;
    this.expandedReport = null;
    this.expandedReportTitle = null;
    this.formatExpandedReportAsCurrency = null;
    this.dataByMonthTab = 24 - 1;

    this.loadActiveCompaniesReport();
    this.loadActiveLoansReport();
  }

  async loadActiveCompaniesReport(): Promise<void> {
    this.activeCompaniesReport = null;

    const [activeCompaniesReportError, activeCompaniesReport] =
      await this.loanService.loansCompaniesDashboardActiveCompaniesByMonthReport(
        {
          groupId: this.filters.groupId
        }
      );

    if (!activeCompaniesReportError && activeCompaniesReport) {
      this._addPercentageByMonthToData(activeCompaniesReport);
      this.activeCompaniesReport = activeCompaniesReport;
    }
  }

  async loadActiveLoansReport(): Promise<void> {
    this.loansCountByMonthReport = null;
    this.loansTotalValueSumByMonthReport = null;
    this.borrowersCountByMonthReport = null;

    const [activeLoansReportError, activeLoansReportsData] =
      await this.loanService.loansCompaniesDashboardActiveLoansByMonthReport({
        groupId: this.filters.groupId
      });

    if (!activeLoansReportError && activeLoansReportsData) {
      const { countByMonth, totalValueSumByMonth, borrowersCountByMonth } =
        activeLoansReportsData;

      this._addPercentageByMonthToData(countByMonth);
      this._addPercentageByMonthToData(totalValueSumByMonth!);
      this._addPercentageByMonthToData(borrowersCountByMonth!);

      this.loansCountByMonthReport = countByMonth;
      this.loansTotalValueSumByMonthReport = totalValueSumByMonth!;
      this.borrowersCountByMonthReport = borrowersCountByMonth!;
    }
  }

  @Watch("subpartnerId")
  updateSubPartnerFilter(subpartner: number): void {
    this.filters.subpartnerId = subpartner;
  }

  _addPercentageByMonthToData(data: MonthData[]) {
    // calculate percentage by month
    const biggestMonthValue = Math.max(
      ...data.map((data: MonthData) => data.value || 0)
    );
    data.forEach((data: MonthData) => {
      data.calculatedPercentage =
        biggestMonthValue > 0 ? (data.value * 100) / biggestMonthValue : 0;
    });
  }

  onClickReport({
    report,
    title,
    subtitle,
    formatAsCurrency
  }: {
    report: MonthData[];
    title: string;
    subtitle: string;
    formatAsCurrency: boolean;
  }): void {
    if (
      !this.expandedReport ||
      (this.expandedReport && this.expandedReport !== report)
    ) {
      this.expandedReport = report;
      this.expandedReportTitle = title;
      this.expandedReportSubtitle = subtitle;
      this.formatExpandedReportAsCurrency = formatAsCurrency;
    } else {
      this.expandedReport = null;
      this.expandedReportTitle = null;
      this.expandedReportSubtitle = null;
    }
  }

  get activeMonth(): MonthData | null {
    if (this.expandedReport) {
      return this.expandedReport[this.dataByMonthTab];
    }

    return null;
  }

  get dashboardFilterNotice(): string {
    if (!this.dashboardLastGroupFilter) {
      return "As estatísticas abaixo são referentes a todas as empresas";
    } else {
      return `As estatísticas abaixo são referentes às empresas do grupo: ${
        this.groupsList.find((g) => g.id === this.dashboardLastGroupFilter)
          ?.name || "-"
      }`;
    }
  }
}
