
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import CreditPolicy, {
  CreditPolicyStatusEnum,
  CreditPolicyTypeEnum,
  CreditPolicyEngineEnum,
  SpecialCreditPolicyRecord,
  SpecialCreditPolicyRecordProductEnum,
  SpecialCreditPolicyRecordProduct
} from "@/types/credit-policy";
import Modal from "@/components/Modal.vue";
import { mask } from "vue-the-mask";
import CreditPolicyService, {
  CreateCreditPolicyParams
} from "@/services/credit-policy-service";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import dayjs from "@/plugins/day-js";
import Segment from "@/types/segment";
import { DataTableHeader } from "vuetify";
import formatDatetime from "../../utils/formatDatetime";

interface SpecialCreditPolicyRecordItem
  extends Omit<SpecialCreditPolicyRecord, "id" | "creditPolicyId" | "product"> {
  products: SpecialCreditPolicyRecordProduct[];
  isEditing: boolean;
  hashes: string[];
}

@Component({
  components: { ValidationObserver, ValidationProvider, Modal },
  directives: { mask }
})
export default class CreditPolicyModal extends Vue {
  @Prop() readonly creditPolicy?: CreditPolicy | undefined;
  @Prop() readonly segments!: Segment[];
  @Prop() readonly specialCreditPoliciesForTemplate?:
    | CreditPolicy[]
    | undefined;
  fullCreditPolicy: CreditPolicy | undefined = undefined;
  formatDatetime = formatDatetime;
  CreditPolicyTypeEnum = CreditPolicyTypeEnum;
  CreditPolicyEngineEnum = CreditPolicyEngineEnum;
  service: CreditPolicyService = CreditPolicyService.getInstance();
  loading: boolean = false;
  form: Omit<CreateCreditPolicyParams, "specialCreditPolicyRecords"> & {
    statusId: number;
    specialCreditPolicyRecords: SpecialCreditPolicyRecordItem[];
  } = {
    statusId: CreditPolicyStatusEnum.ACTIVE.id,
    typeId: CreditPolicyTypeEnum.BY_SEGMENT.id,
    segmentName: "",
    engineId: CreditPolicyEngineEnum.DEFAULT.id,
    identifier: "",
    description: "",
    specialCreditPolicyRecords: []
  };
  preEditRecordBackup: SpecialCreditPolicyRecordItem | null = null;
  statusOptions = Object.values(CreditPolicyStatusEnum).map((status) => ({
    id: status.id,
    name: status.description
  }));
  blankSpecialCreditPolicy = { id: 0, identifier: "Nova" } as CreditPolicy;
  specialCreditPolicies: CreditPolicy[] = [this.blankSpecialCreditPolicy];
  selectedSpecialCreditPolicy: CreditPolicy | null =
    this.blankSpecialCreditPolicy;
  headersForSpecialCreditPolicyRecords: Array<DataTableHeader> = [
    { text: "Renda mínima", value: "minIncome" },
    { text: "Renda máxima", value: "maxIncome" },
    { text: "Vezes renda", value: "timesIncome" },
    { text: "Tempo mínimo", value: "minTime" },
    { text: "Tempo máximo", value: "maxTime" },
    { text: "Taxa", value: "feePercentage" },
    { text: "Prazo mínimo", value: "minNumInstallments" },
    { text: "Prazo máximo", value: "numInstallments" },
    { text: "Negativado", value: "withRestriction", width: "120px" },
    {
      text: "Produto",
      value: "product.description",
      width: "250px",
      sortable: true
    },
    { text: "Ações", value: "actions", width: "195px", sortable: false }
  ];
  withRestrictionOptions = [
    {
      text: "NÃO",
      value: false
    },
    {
      text: "SIM",
      value: true
    },
    {
      text: "AMBOS",
      value: null
    }
  ];
  withRestrictionValueToText = Object.fromEntries(
    this.withRestrictionOptions.map((option) => [option.value, option.text])
  );
  productOptions = [
    { id: 0, name: "TODOS", description: "TODOS" },
    ...Object.values(SpecialCreditPolicyRecordProductEnum)
  ];
  loadingFullPolicy = false;
  isEditingSpecialCreditPolicyRecord = false;

  constructor() {
    super();

    if (this.creditPolicy) {
      this.fullCreditPolicy = { ...this.creditPolicy };

      this.form = {
        statusId: this.creditPolicy.statusId,
        typeId: this.creditPolicy.typeId,
        segmentName: this.creditPolicy.segment?.name,
        engineId: this.creditPolicy.engineId,
        identifier: this.creditPolicy.identifier,
        description: this.creditPolicy.description,
        specialCreditPolicyRecords: this.prepareSpecialCreditPolicyRecords(
          this.creditPolicy.specialCreditPolicyRecords
        )
      };
    }
  }

  async created(): Promise<void> {
    if (this.creditPolicy) {
      const fullCreditPolicy = await this.loadFullCreditPolicy(
        this.creditPolicy.id
      );
      this.fullCreditPolicy = fullCreditPolicy;

      if (this.form.typeId === CreditPolicyTypeEnum.SPECIAL.id) {
        this.form.specialCreditPolicyRecords =
          this.prepareSpecialCreditPolicyRecords(
            fullCreditPolicy.specialCreditPolicyRecords
          );
      }
    }
    this.loadSpecialCreditPoliciesForTemplate();
  }

  async loadFullCreditPolicy(creditPolicyId: number): Promise<CreditPolicy> {
    this.loadingFullPolicy = true;

    const [error, fullCreditPolicy] = await this.service.getCreditPolicy(
      creditPolicyId,
      {
        loadEventHistory: true,
        loadSpecialCreditPolicyRecords:
          this.form.typeId === CreditPolicyTypeEnum.SPECIAL.id
      }
    );
    this.loadingFullPolicy = false;

    if (error || !fullCreditPolicy) {
      this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    } else {
      return fullCreditPolicy;
    }
  }

  async loadSpecialCreditPoliciesForTemplate(): Promise<void> {
    this.specialCreditPolicies = [
      this.blankSpecialCreditPolicy,
      ...(this.specialCreditPoliciesForTemplate || [])
    ];
  }

  @Watch("selectedSpecialCreditPolicy")
  async onSelectedSpecialCreditPolicyChange(): Promise<void> {
    if (this.selectedSpecialCreditPolicy) {
      if (
        this.selectedSpecialCreditPolicy.id === this.blankSpecialCreditPolicy.id
      ) {
        this.form.specialCreditPolicyRecords = [];
      } else {
        const fullSelectedSpecialCreditPolicy = await this.loadFullCreditPolicy(
          this.selectedSpecialCreditPolicy.id
        );
        this.form.specialCreditPolicyRecords =
          this.prepareSpecialCreditPolicyRecords(
            fullSelectedSpecialCreditPolicy.specialCreditPolicyRecords
          );
      }
    }
  }

  handleProductSelection(item: SpecialCreditPolicyRecordItem): void {
    const todosId = 0;

    // If "TODOS" is selected, select all real products
    if (item.products.find((p) => p.id === todosId)) {
      item.products = this.productOptions.filter((p) => p.id !== todosId);
    }
  }

  prepareSpecialCreditPolicyRecords(
    records?: SpecialCreditPolicyRecord[]
  ): SpecialCreditPolicyRecordItem[] {
    return (records || []).map((record) => {
      record.id = undefined;
      record.creditPolicyId = undefined;
      return {
        ...record,
        product: undefined,
        products: [record.product],
        isEditing: false,
        hashes: this.getSpecialCreditPolicyRecordHashes(record, {
          requiresFlattening: false
        })
      };
    });
  }

  addSpecialCreditPolicyRecord(): void {
    this.isEditingSpecialCreditPolicyRecord = true;

    const blankRecord = {
      minIncome: 0,
      maxIncome: 0,
      timesIncome: 0,
      minTime: 0,
      maxTime: 0,
      feePercentage: 0,
      minNumInstallments: 0,
      numInstallments: 0,
      withRestriction: false,
      products: [
        this.productOptions.find(
          (p) => p.id !== 0
        ) as SpecialCreditPolicyRecordProduct
      ],
      isEditing: true,
      hashes: [""]
    };

    this.form.specialCreditPolicyRecords.push({
      ...blankRecord,
      hashes: this.getSpecialCreditPolicyRecordHashes(blankRecord, {
        requiresFlattening: true
      })
    });
  }

  startEditingRecord(record: SpecialCreditPolicyRecordItem): void {
    this.preEditRecordBackup = { ...record };
    this.handleEditing(record, true);
  }

  stopEditingRecord(record: SpecialCreditPolicyRecordItem): void {
    record.products = record.products.filter((p) => p.id !== 0); // Remove "TODOS" fake option

    const hashes = this.getSpecialCreditPolicyRecordHashes(record, {
      requiresFlattening: true
    });

    if (record.minNumInstallments > record.numInstallments) {
      this.$notify({
        type: "error",
        text: "O valor de prazo mínimo não pode ser maior que prazo máximo."
      });
      return;
    }

    const hasDuplicateHash = this.form.specialCreditPolicyRecords.some(
      (existingRecord) =>
        existingRecord.hashes.some((h) => hashes.includes(h)) &&
        !existingRecord.isEditing
    );

    if (hasDuplicateHash) {
      this.$notify({
        type: "error",
        text: "Não é possível salvar registros duplicados."
      });
      return;
    }

    if (!this.validateOverlappingRanges(record, hashes)) {
      return;
    }

    record.hashes = hashes;
    this.handleEditing(record, false);
  }

  cancelEditingRecord(item: SpecialCreditPolicyRecordItem): void {
    const originalRecord = this.preEditRecordBackup;
    if (originalRecord) {
      Object.assign(item, originalRecord); // Restore original record
    }
    this.handleEditing(item, false); // Exit editing mode
  }

  validateOverlappingRanges(
    record: SpecialCreditPolicyRecordItem,
    hashes: string[]
  ): boolean {
    const isOverlappingRange = (
      existingRecord: SpecialCreditPolicyRecordItem
    ): boolean => {
      const isSameProductAndRestriction =
        existingRecord.products.some((p) =>
          record.products.find((rp) => rp.id === p.id)
        ) &&
        (existingRecord.withRestriction === record.withRestriction ||
          existingRecord.withRestriction === null ||
          record.withRestriction === null);

      const isIncomeOverlapping =
        (record.minIncome >= existingRecord.minIncome &&
          record.minIncome <= existingRecord.maxIncome) ||
        (record.maxIncome >= existingRecord.minIncome &&
          record.maxIncome <= existingRecord.maxIncome);

      const isTimeOverlapping =
        (record.minTime >= existingRecord.minTime &&
          record.minTime <= existingRecord.maxTime) ||
        (record.maxTime >= existingRecord.minTime &&
          record.maxTime <= existingRecord.maxTime) ||
        (record.minTime === existingRecord.minTime &&
          record.maxTime === existingRecord.maxTime);

      const isInstallmentsOverlapping =
        (record.minNumInstallments >= existingRecord.minNumInstallments &&
          record.minNumInstallments <= existingRecord.numInstallments) ||
        (record.numInstallments >= existingRecord.minNumInstallments &&
          record.numInstallments <= existingRecord.numInstallments);

      return (
        isSameProductAndRestriction &&
        isIncomeOverlapping &&
        isTimeOverlapping &&
        isInstallmentsOverlapping
      );
    };

    const hasOverlappingRange = this.form.specialCreditPolicyRecords.some(
      (existingRecord) =>
        existingRecord.hashes.some((h) => !hashes.includes(h)) &&
        !existingRecord.isEditing &&
        isOverlappingRange(existingRecord)
    );

    if (hasOverlappingRange) {
      this.$notify({
        type: "error",
        text: "Não é possível adicionar um range que se sobrepõe a outro existente com o mesmo prazo."
      });
      return false;
    }

    return true;
  }

  removeRecord(record: SpecialCreditPolicyRecordItem): void {
    this.handleEditing(record, false);
    this.form.specialCreditPolicyRecords =
      this.form.specialCreditPolicyRecords.filter((r) => r !== record);
  }

  handleEditing(record: SpecialCreditPolicyRecordItem, editing: boolean): void {
    this.isEditingSpecialCreditPolicyRecord = editing;
    record.isEditing = editing;
    if (!editing) {
      this.preEditRecordBackup = null;
    }
  }

  getSpecialCreditPolicyRecordHashes(
    record: SpecialCreditPolicyRecord | SpecialCreditPolicyRecordItem,
    options: { requiresFlattening: boolean }
  ): string[] {
    const KEYS_FOR_HASH: Array<
      keyof SpecialCreditPolicyRecord | keyof SpecialCreditPolicyRecordItem
    > = [
      "minIncome",
      "maxIncome",
      "timesIncome",
      "minTime",
      "maxTime",
      "feePercentage",
      "numInstallments",
      "minNumInstallments",
      "withRestriction",
      "product"
    ];

    const generateHash = (
      rec: SpecialCreditPolicyRecord | SpecialCreditPolicyRecordItem
    ) => {
      return Object.keys(rec)
        .filter((key) =>
          KEYS_FOR_HASH.includes(
            key as
              | keyof SpecialCreditPolicyRecord
              | keyof SpecialCreditPolicyRecordItem
          )
        )
        .sort() // Sort the keys alphabetically
        .reduce((acc, key) => {
          if (key === "product") {
            acc += rec[key].id + "|";
          } else if (
            [
              "minIncome",
              "maxIncome",
              "timesIncome",
              "minTime",
              "maxTime",
              "feePercentage",
              "numInstallments",
              "minNumInstallments"
            ].includes(key)
          ) {
            acc += String(Number(rec[key])) + "|";
          } else if (key === "withRestriction") {
            acc += String(rec[key]) + "|";
          } else {
            acc += rec[key] + "|";
          }
          return acc;
        }, "");
    };

    if (options.requiresFlattening) {
      return this.flattenRecord(record as SpecialCreditPolicyRecordItem).map(
        (r) => generateHash(r as SpecialCreditPolicyRecord)
      );
    } else {
      return [generateHash(record)];
    }
  }

  flattenRecord(
    record: SpecialCreditPolicyRecordItem
  ): Array<Omit<SpecialCreditPolicyRecord, "id" | "creditPolicyId">> {
    let flattedRecord:
      | SpecialCreditPolicyRecordItem[]
      | Array<Omit<SpecialCreditPolicyRecord, "id" | "creditPolicyId">> = [
      { ...record, isEditing: undefined, hashes: undefined } // Remove properties that are not part of SpecialCreditPolicyRecord]
    ];

    flattedRecord = flattedRecord.flatMap((rec) =>
      rec.withRestriction === null
        ? [
            { ...rec, withRestriction: false },
            { ...rec, withRestriction: true }
          ]
        : [{ ...rec }]
    );

    flattedRecord = flattedRecord.flatMap<
      Omit<SpecialCreditPolicyRecord, "id" | "creditPolicyId">
    >((rec) =>
      rec.products.map((product) => ({
        ...rec,
        products: undefined,
        product
      }))
    );

    return flattedRecord;
  }

  async save(): Promise<void> {
    let error, creditPolicy;
    this.loading = true;

    const form = {
      ...this.form,
      specialCreditPolicyRecords: this.form.specialCreditPolicyRecords.flatMap(
        this.flattenRecord
      )
    };

    if (this.creditPolicy) {
      [error, creditPolicy] = await this.service.updateCreditPolicy(
        this.creditPolicy.id,
        form
      );
    } else {
      [error, creditPolicy] = await this.service.createCreditPolicy(form);
    }
    this.loading = false;
    if (!error) {
      this.$notify({ type: "success", text: "Política salva com sucesso" });
      this.$emit("input", creditPolicy);
      this.close();
    } else {
      this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    }
  }

  close(): void {
    this.$emit("close");
  }

  formatDate(date: string): string {
    return dayjs(date).format("DD/MM/YYYY");
  }

  get formTitle(): string {
    return this.creditPolicy
      ? "Editar política de crédito"
      : "Nova política de crédito";
  }
}
