export default function calculateDiffInPercentage({
  newValue,
  previousValue
}: {
  newValue: number;
  previousValue: number;
}): number | null {
  if (previousValue === 0) return null;

  return Number(((newValue / previousValue - 1) * 100).toFixed(1));
}
