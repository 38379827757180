
import { Vue, Component, Watch } from "vue-property-decorator";
import Page from "@/components/core/dashboard/Page.vue";
import LoanService, {
  ListDownloadedReportsResponse
} from "../services/loan-service";
import { DataOptions } from "vuetify";
import formatDatetime from "@/utils/formatDatetime";
import { DownloadedReportStatusEnum } from "../types/downloaded-report";

@Component({
  components: {
    Page
  }
})
export default class MyReports extends Vue {
  DownloadedReportStatusEnum = DownloadedReportStatusEnum;
  formatDatetime = formatDatetime;
  fileManagerUrl: string = process.env.VUE_APP_FILE_MANAGER_URL || "";
  loanService: LoanService = LoanService.getInstance();
  headers = [
    { text: "Data solicitação", value: "downloadDate", sortable: false },
    { text: "Nome Arquivo", value: "storageKey", sortable: false },
    { text: "Origem", value: "type.description", sortable: false },
    { text: "Status", value: "status.description", sortable: false },
    { text: "Ações", value: "actions", sortable: false, width: "40px" }
  ];
  options: DataOptions = {} as DataOptions;
  reports: ListDownloadedReportsResponse = {
    items: [],
    total: 0
  };
  loading: boolean = false;

  @Watch("options")
  onOptionsChange(val: DataOptions): void {
    this.fetchReports(val);
  }

  // async mounted(): Promise<void> {
  //   this.fetchReports();
  // }
  getFileName(storageKey: string): string {
    return storageKey ? storageKey.split('/').pop() : 'Processando'
  }

  async fetchReports(options: DataOptions): Promise<void> {
    this.loading = true;
    const [error, data] = await this.loanService.listDownloadedReports({
      page: options.page,
      limit: options.itemsPerPage
    });
    this.loading = false;
    if (error) {
      console.error(error);
      return;
    }
    this.reports = data!;
  }
}
