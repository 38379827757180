export interface DownloadedReport {
  id: number;
  userId: number;
  userType: string;
  type: DownloadedReportType;
  typeId: number;
  status: DownloadedReportStatus;
  statusId: number;
  downloadDate: string;
  storageKey?: string | null;
  created_at: Date;
  updated_at: Date;
}

export interface DownloadedReportType {
  id: number;
  name: string;
  description: string;
}

export interface DownloadedReportStatus {
  id: number;
  name: string;
  description: string;
}

const loansGroups: DownloadedReportType = {} as DownloadedReportType;
loansGroups.id = 1;
loansGroups.name = "EMPRESTIMOS_GRUPOS";
loansGroups.description = "Empréstimos Grupos";

const loansCompanies: DownloadedReportType = {} as DownloadedReportType;
loansCompanies.id = 2;
loansCompanies.name = "EMPRESTIMOS_EMPRESAS";
loansCompanies.description = "Empréstimos Empresas";

const loansBorrowers: DownloadedReportType = {} as DownloadedReportType;
loansBorrowers.id = 3;
loansBorrowers.name = "EMPRESTIMOS_COLABORADORES";
loansBorrowers.description = "Empréstimos Colaboradores";

const loansDetail: DownloadedReportType = {} as DownloadedReportType;
loansDetail.id = 4;
loansDetail.name = "EMPRESTIMOS_DETALHES";
loansDetail.description = "Empréstimos Detalhes";

const remittancesFunds: DownloadedReportType = {} as DownloadedReportType;
remittancesFunds.id = 5;
remittancesFunds.name = "REMESSAS_FUNDOS";
remittancesFunds.description = "Remessas Fundos";

const remittancesDetailsFunds: DownloadedReportType =
  {} as DownloadedReportType;
remittancesDetailsFunds.id = 6;
remittancesDetailsFunds.name = "DETALHES_LOTES_FUNDOS";
remittancesDetailsFunds.description = "Detalhes Lotes Fundos";

const fileRemittanceFunds: DownloadedReportType = {} as DownloadedReportType;
fileRemittanceFunds.id = 7;
fileRemittanceFunds.name = "ARQUIVO_LOTES_FUNDOS";
fileRemittanceFunds.description = "Arquivo Lotes Fundos";

export const DownloadedReportTypeEnum = {
  LOANS_GROUPS: loansGroups,
  LOANS_COMPANIES: loansCompanies,
  LOANS_BORROWERS: loansBorrowers,
  LOANS_DETAIL: loansDetail,
  REMITTANCES_FUNDS: remittancesFunds,
  DETAILS_REMITTANCES_FUNDS: remittancesDetailsFunds,
  FILE_REMITTANCES_FUNDS: fileRemittanceFunds
};

const queued: DownloadedReportStatus = {} as DownloadedReportStatus;
queued.id = 1;
queued.name = "AGUARDANDO_PROCESSAMENTO";
queued.description = "Aguardando processamento";

const processed: DownloadedReportStatus = {} as DownloadedReportStatus;
processed.id = 2;
processed.name = "PROCESSADO";
processed.description = "Processado";

export const DownloadedReportStatusEnum = {
  QUEUED: queued,
  PROCESSED: processed
};
