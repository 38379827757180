export default interface RemittanceOccurrenceType {
  id: number;
  name: string;
  readableName: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}

const acquisition: RemittanceOccurrenceType = {} as RemittanceOccurrenceType;
acquisition.id = 1;
acquisition.name = "AQUISICAO";
acquisition.readableName = "Aquisição de títulos";

const conciliation: RemittanceOccurrenceType = {} as RemittanceOccurrenceType;
conciliation.id = 2;
conciliation.name = "CONCILIACAO";
conciliation.readableName = "Conciliação de títulos";

const assignment: RemittanceOccurrenceType = {} as RemittanceOccurrenceType;
assignment.id = 3;
assignment.name = "BANCARIZACAO";
assignment.readableName = "Bancarização de títulos";

export const RemittanceOccurrenceTypeEnum = {
  ACQUISITION: acquisition,
  CONCILIATION: conciliation,
  ASSIGNMENT: assignment
};
