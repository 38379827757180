
import { Vue, Component, Watch } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import CompanyService from "@/services/company-service";
import LoanService from "@/services/loan-service";
import Modal from "@/components/Modal.vue";
import DatePicker from "@/components/DatePicker.vue";
import CompaniesLoansReport from "@/components/management-reports/CompaniesLoansReport.vue";
import {
  GroupsLoansReportData,
  GroupsLoansReportFilters
} from "@/services/loan-service/types/management-reports";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import LoanStatusGroup from "@/types/loan-status-group";
import PartnerService from "../../services/partner-service";
import Partner from "@/types/partner";
import { format as formatDocument } from "@/utils/cpfOrCnpj";
import { MonthData } from "../../types/dashboard-reports";
import { MonthShortAndLongForm } from "@/utils/months";
import DashboardReportPercentage from "@/components/DashboardReportPercentage.vue";
import formatCurrency from "@/utils/formatCurrency";
import calculateDiffInPercentage from "@/utils/calculate-diff-in-percentage";
import SafetyService from "@/services/safety-service";
import User from "@/types/user";

@Component({
  components: {
    Modal,
    DatePicker,
    CompaniesLoansReport,
    DashboardReportPercentage
  }
})
export default class GroupsLoansReport extends Vue {
  formatCurrency = (value: number) => {
    if (value >= 10000) return formatCurrency(value, "compact");
    return formatCurrency(value);
  };
  formatDocument = formatDocument;
  calculateDiffInPercentage = calculateDiffInPercentage;
  MonthShortAndLongForm = MonthShortAndLongForm;
  groupId: number | null = null;
  companyService: CompanyService;
  loanService: LoanService;
  partnerService: PartnerService;
  safetyService: SafetyService;
  reports: GroupsLoansReportData;
  filters: GroupsLoansReportFilters;
  headers: Array<DataTableHeader>;
  partnersList: Array<Partner>;
  loading = true;
  loadingXls = false;
  dateFilterTypes: Array<string> = [
    "Data de contratação",
    "Data de início da simulação",
    "Data de vencimento da parcela"
  ];
  dateFilterType = "Data de contratação";
  responsableCommercialList: Array<User> | null = null;
  statusGroupList: LoanStatusGroup[] | null = null;
  activeGroupsReport: MonthData[] | null = null;
  loansCountReport: MonthData[] | null = null;
  totalValueSumByMonthReport: MonthData[] | null = null;
  activeLoansBorrowersCountReport: MonthData[] | null = null;
  dataByMonthTab: number = 0;
  expandedReport: MonthData[] | null = null;
  expandedReportTitle: string | null = null;
  expandedReportSubtitle: string | null = null;
  formatExpandedReportAsCurrency: boolean | null = null;

  constructor() {
    super();
    this.companyService = CompanyService.getInstance();
    this.loanService = LoanService.getInstance();
    this.partnerService = PartnerService.getInstance();
    this.safetyService = SafetyService.getInstance();
    this.responsableCommercialList = [];
    this.partnersList = [];
    this.headers = [
      { text: "Grupo", value: "groupName", sortable: true },
      { text: "Total Contratos", value: "loansCount", sortable: false },
      {
        text: "Valor Total Contratos",
        value: "loansTotalValue",
        sortable: false
      },
      {
        text: "Total Empresas Ativas",
        value: "companiesCount",
        sortable: false
      },
      {
        text: "Total Colaboradores",
        value: "loansCountBorrowers",
        sortable: false
      },
      { text: "Parceiro", value: "partnerId", sortable: false },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        cellClass: "text-end",
        class: "text-end"
      }
    ];
    this.filters = {
      page: 1,
      limit: 10,
      sort: "groupName:ASC",
      groupName: "",
      partnerIds: null,
      subpartnerId: null,
      loanInstallmentDueDateStart: null,
      loanInstallmentDueDateEnd: null,
      startedSimulationDateStart: null,
      startedSimulationDateEnd: null,
      requestDateStart: null,
      requestDateEnd: null,
      statusGroupId: 2,
      responsibleCommercialId: null
    };
    this.reports = {
      items: [],
      total: 0
    };
  }

  mounted(): void {
    this.loadPartnersList();
    this.loadStatusGroupList();
    this.loadCommercials();
    this.loadReports();
  }

  async loadStatusGroupList(): Promise<void> {
    const [statusGroupListError, statusGroupList] =
      await this.loanService.getStatusGroupList();

    if (statusGroupListError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de grupos de status para o filtro."
      });
    }

    this.statusGroupList = statusGroupList;
  }

  async loadCommercials(): Promise<void> {
    const [userListError, comercialList] = await this.safetyService.listUsers({
      page: 1,
      limit: -1,
      types: ["ADMIN_GOOROO"]
    });

    if (userListError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar os usuários do comercial."
      });
    }

    this.responsableCommercialList = comercialList.items;
  }

  async loadPartnersList(): Promise<void> {
    const [partnersError, partnersData] =
      await this.partnerService.listPartners({
        page: 1,
        limit: -1
      });

    if (!partnersData || partnersError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de parceiros para o filtro."
      });
    } else {
      this.partnersList = partnersData.data;
    }
  }

  loadFromStart() {
    this.filters.page = 1;
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.loading = true;

    const [groupsLoansReportError, groupsLoansReportData] =
      await this.loanService.getGroupsLoansReport(this.filters);

    if (groupsLoansReportError) {
      this.$notify({
        type: "error",
        text: "Ocorreu um erro ao buscar os dados do relatório"
      });
    } else if (groupsLoansReportData) {
      this.reports = groupsLoansReportData;
    }

    this.loading = false;
  }

  async downloadXls(): Promise<void> {
    this.loadingXls = true;
    const [error, data] = await this.loanService.generateGroupsLoansReportXls(
      this.filters
    );
    if (!error) {
      window.open(
        process.env.VUE_APP_LOAN_URL! +
          "/reports/download-xls?token=" +
          data!.token,
        "_blank"
      );
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loadingXls = false;
  }

  onOptionsChange(tableOptions: DataOptions): DataOptions {
    this.filters.page = tableOptions.page;
    this.filters.limit = tableOptions.itemsPerPage;
    this.filters.sort = this.formatSort(
      tableOptions.sortBy,
      tableOptions.sortDesc
    );
    this.loadData();

    return tableOptions;
  }

  formatSort(sortBy: Array<string>, sortDesc: Array<boolean>): string {
    const attr = sortBy[0] ?? "groupName";
    const order = sortDesc[0] ? "ASC" : "DESC";

    return `${attr}:${order}`;
  }

  @Watch("dateFilterType")
  onDateFilterTypeChange(): void {
    this.filters.loanInstallmentDueDateStart = null;
    this.filters.loanInstallmentDueDateEnd = null;
    this.filters.startedSimulationDateStart = null;
    this.filters.startedSimulationDateEnd = null;
    this.filters.requestDateStart = null;
    this.filters.requestDateEnd = null;
  }

  @Watch("subpartnerId")
  updateSubPartnerFilter(): void {
    this.filters.subpartnerId = this.filters.subpartnerId || null;
  }

  @Watch("responsibleCommercialId")
  updateReponsabelCommercialFilter(): void {
    this.filters.responsibleCommercialId =
      this.filters.responsibleCommercialId || null;
  }

  setPartnerFilter(v: string[]): void {
    this.filters.partnerIds = v.map((x) => Number(x));
    this.filters.subpartnerId = null;
  }

  async loadReports(): Promise<void> {
    this.activeGroupsReport = null;
    this.loansCountReport = null;
    this.totalValueSumByMonthReport = null;
    this.activeLoansBorrowersCountReport = null;
    this.expandedReport = null;
    this.expandedReportTitle = null;
    this.formatExpandedReportAsCurrency = null;
    this.dataByMonthTab = 24 - 1;

    const [activeGroupsReportError, activeGroupsReport] =
      await this.loanService.loansGroupsDashboardActiveGroupsByMonthReport();
    if (!activeGroupsReportError && activeGroupsReport) {
      this._addPercentageByMonthToData(activeGroupsReport);
      this.activeGroupsReport = activeGroupsReport;
    }

    const [activeLoansReportError, activeLoansReportsData] =
      await this.loanService.loansGroupsDashboardActiveLoansByMonthReport();
    if (!activeLoansReportError && activeLoansReportsData) {
      const { countByMonth, totalValueSumByMonth, borrowersCountByMonth } =
        activeLoansReportsData;

      this._addPercentageByMonthToData(countByMonth);
      this._addPercentageByMonthToData(totalValueSumByMonth!);
      this._addPercentageByMonthToData(borrowersCountByMonth!);

      this.loansCountReport = countByMonth;
      this.totalValueSumByMonthReport = totalValueSumByMonth!;
      this.activeLoansBorrowersCountReport = borrowersCountByMonth!;
    }
  }

  _addPercentageByMonthToData(data: MonthData[]) {
    // calculate percentage by month
    const biggestMonthValue = Math.max(
      ...data.map((data: MonthData) => data.value || 0)
    );
    data.forEach((data: MonthData) => {
      data.calculatedPercentage =
        biggestMonthValue > 0 ? (data.value * 100) / biggestMonthValue : 0;
    });
  }

  get activeMonth(): MonthData | null {
    if (this.expandedReport) {
      return this.expandedReport[this.dataByMonthTab];
    }

    return null;
  }

  onClickReport({
    report,
    title,
    subtitle,
    formatAsCurrency
  }: {
    report: MonthData[];
    title: string;
    subtitle: string;
    formatAsCurrency: boolean;
  }): void {
    if (
      !this.expandedReport ||
      (this.expandedReport && this.expandedReport !== report)
    ) {
      this.expandedReport = report;
      this.expandedReportTitle = title;
      this.expandedReportSubtitle = subtitle;
      this.formatExpandedReportAsCurrency = formatAsCurrency;
    } else {
      this.expandedReport = null;
      this.expandedReportTitle = null;
      this.expandedReportSubtitle = null;
    }
  }
}
