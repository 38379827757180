
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import LoanService from "@/services/loan-service";
import Modal from "@/components/Modal.vue";

import DatePicker from "@/components/DatePicker.vue";
import {
  LoanRemittanceReportsFilters,
  LoanRemittanceReportsItem,
  LoanRemittanceReportsItemsList
} from "@/services/loan-service/types/management-reports";
import LoanStatusGroup from "@/types/loan-status-group";
import formatDatetime from "@/utils/formatDatetime";
import { format as formatCNPJ } from "@/utils/cnpj";
import { format } from "@/utils/cpf";
import RemittancesFundsDetailsReport from "./RemittancesFundsDetailsReport.vue";
import formatCurrency from "@/utils/formatCurrency";
import CompanyService, {
  ListCompaniesResponse
} from "@/services/company-service";
import Company from "@/types/company";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import { RemittanceOccurrenceTypeEnum } from "@/types/remittance-occurrence-type";
import LoanInstallment from "@/types/loan-installment";
import RemittanceOccurrences from "@/types/remittance-occurrences";

@Component({
  components: {
    Modal,
    DatePicker,
    RemittancesFundsDetailsReport
  }
})
export default class RemittanceFundsReport extends Vue {
  @Prop() readonly remittanceId?: number;

  loanService: LoanService;
  companyService: CompanyService;
  selectedCompany: Partial<Company> | null = null;
  contract: number | null = null;
  loan: LoanRemittanceReportsItemsList;
  companiesList: ListCompaniesResponse = {
    items: [],
    total: 0
  };
  filters: LoanRemittanceReportsFilters;
  headers: Array<DataTableHeader>;
  formatCNPJ = formatCNPJ;
  formatCPF = format;
  formatCurrency = formatCurrency;
  formatDatetime = formatDatetime;
  fundTypes: Array<{
    name: string;
    id: number;
  }>;
  loading = true;
  loadingExcel = false;
  statusGroupList: LoanStatusGroup[] | null = null;
  fileManagerUrl: string;
  dateFilterTypes: Array<string> = ["Data de Remessa", "Data do empréstimo"];
  dateFilterType = "Data de Remessa";

  constructor() {
    super();
    this.loanService = LoanService.getInstance();
    this.companyService = CompanyService.getInstance();
    this.headers = [
      {
        text: "Data Remessa",
        value: "remittanceCreatedAt",
        sortable: false
      },
      {
        text: "Empresa - CNPJ",
        value: "companyName",
        sortable: false
      },
      { text: "Colaborador - CPF", value: "borrowerName", sortable: false },
      { text: "Contrato", value: "proposalNumber", sortable: false },
      { text: "Proposta", value: "id", sortable: false },
      {
        text: "Valor Contrato",
        value: "total",
        sortable: false
      },
      {
        text: "Valor Aquisição",
        value: "loanAcquisitionValue",
        sortable: false
      },
      {
        text: "Parcelas",
        value: "numInstallments",
        sortable: false
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        cellClass: "text-end",
        class: "text-end"
      }
    ];
    this.fundTypes = [
      {
        name: "Money Plus",
        id: 1
      },
      {
        name: "Limine",
        id: 2
      },
      {
        name: "Santa Bárbara",
        id: 3
      },
      {
        name: "Gooroo",
        id: 4
      }
    ];
    this.filters = {
      page: 1,
      limit: 10,
      remittanceId: this.remittanceId,
      financialId: null,
      dateRemittanceStart: null,
      dateRemittanceEnd: null
    };
    this.loan = {
      items: [],
      total: 0
    };
    this.fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL || "";
  }

  created(): void {
    this.loadFilters();
  }

  async loadLoanRemittances(): Promise<void> {
    this.loading = true;

    const validation =
      this.filters.dateRemittanceStart &&
      this.filters.dateRemittanceEnd &&
      this.filters.dateRemittanceStart > this.filters.dateRemittanceEnd
        ? "a data final não pode ser menor que a data inicial"
        : undefined;
    const [remittanceLoansReportError, remittanceLoansReportData] =
      await this.loanService.getLoanRemittanceReports(this.filters);

    if (remittanceLoansReportError) {
      this.$notify({
        type: "error",
        text: validation ?? "Ocorreu um erro ao buscar os dados do relatório"
      });
    } else if (remittanceLoansReportData) {
      this.loan = remittanceLoansReportData;
    }

    this.loading = false;
  }

  async loadFilters(): Promise<void> {
    const [companiesError, companiesData] =
      await this.companyService.listCompanies({
        page: 1,
        limit: 100000,
        sort: "name:ASC",
        search: ""
      });
    if (companiesError) {
      this.$notify({
        type: "error",
        text: "Ocorreu um erro ao buscar os dados do relatório"
      });
    } else if (companiesData) {
      this.companiesList = companiesData;
    }
  }

  updateCompanyFilters(): void {
    if (this.selectedCompany) {
      this.filters.companyId = this.selectedCompany.id;
    } else {
      this.filters.companyId = null;
    }
  }

  async filterFromStart(): Promise<void> {
    this.filters.page = 1;
    this.loadLoanRemittances();
  }

  async downloadXls(): Promise<void> {
    this.loadingExcel = true;
    const [error, data] =
      await this.loanService.generateRemittancesFundsReportXls(this.filters);
    if (!error) {
      window.open(
        process.env.VUE_APP_LOAN_URL! +
          "/reports/download-xls?token=" +
          data!.token,
        "_blank"
      );
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loadingExcel = false;
  }

  onOptionsChange(tableOptions: DataOptions): DataOptions {
    this.filters.page = tableOptions.page;
    this.filters.limit = tableOptions.itemsPerPage;
    this.loadLoanRemittances();

    return tableOptions;
  }

  formatSort(sortBy: Array<string>, sortDesc: Array<boolean>): string {
    const attr = sortBy[0] ? sortBy[0].split(".")[1] : "id";
    const order = sortDesc[0] ? "DESC" : "ASC";

    return `${attr}:${order}`;
  }

  @Watch("dateFilterType")
  onDateFilterTypeChange(): void {
    this.filters.dateRemittanceStart = null;
    this.filters.dateRemittanceEnd = null;
  }

  formatDate(date: string): string {
    if (!date) return "";
    const [data] = date.split("T");
    const [ano, mes, dia] = data.split("-");
    const dataFormatada = `${dia}-${mes}-${ano}`;
    return dataFormatada;
  }
}
